import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { OnlineComponent } from './online/online.component';
import * as moment from 'moment';
import { GoogleChartEditor, GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';

import { DashboardService } from 'src/app/services/dashboard.service';
import { UsuarioService } from 'src/app/services/usuario.service';

declare let $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  canvas: any;
  ctx: any;
  tc: any = [];
  form = this.formBuilder.group({
    trabajador: ['']
  });

  list_cap = [];
  listTrabajador: any[] = [];
  infoUsers: any;
  visible_user_online: boolean = false;
  visible_cap: boolean = false;
  visible_users_area: boolean = false;
  load_grafica: boolean = false;

  public usersOnlineChart: GoogleChartInterface = {
    chartType: GoogleChartType.ComboChart,
    dataTable: [],
    options: {
      seriesType: 'bars',
      series: {20: {type: 'line'}}
    },
  };

  public usersByAreaChart: GoogleChartInterface = {
    chartType: GoogleChartType.ComboChart,
    dataTable: [],
    options: {
      seriesType: 'bars',
      series: {20: {type: 'line'}}
    },
  };

  constructor(private dashboardService: DashboardService,
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alert: AlertService)
  { }

  ngOnInit() {
    $('.select_person').select2();
    this.loadCodeUsers();
    this.obtenerTipoCambio();
    this.mostrarUsuarios();
    this.viewUsersConection();
    this.viewUsersByArea();
  }

  loadCodeUsers() {
    this.usuarioService.getCodeUsuarios()
      .subscribe((data:any) => {
        this.listTrabajador = data.data.data;
      });
  }

  obtenerTipoCambio() {
    let fecha = moment().format('YYYY-MM-DD');
    this.dashboardService.getTipoCambio(fecha)
      .subscribe((data: any) => {
        this.tc = data.data.data;
      });
  }

  mostrarUsuarios() {
    this.dashboardService.getUsers()
      .subscribe((data: any) => {
        this.infoUsers = data.data.data;
      });
  }

  onlineUsers() {
    console.log('mostrarUsuarios');
    const dialogRef = this.dialog.open(OnlineComponent, {
      disableClose: true,
      maxWidth: '640px',
      data: 'online'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.mostrarUsuarios();
        }
      });
  }

  viewUsersConection() {
    this.dashboardService.getUsersConection()
      .subscribe((data: any) => {
        if (data.data.data.response[0].length > 1) {
          this.usersOnlineChart.dataTable = data.data.data.response;
          this.usersOnlineChart.options = {
            seriesType: 'bars',
            legend: { position: 'bottom', alignment: 'start' },
          };
          this.visible_user_online = true;
        }
      });
  }

  viewCapacitacionByUser() {
    if ($('.select_person').val()) {
      let codigo = $('.select_person').val();
      this.visible_cap = false;
      this.load_grafica = true;
      this.dashboardService.getCapacitacionByCodeUser(codigo)
        .subscribe((data: any) => {
          if (data.data.data[0].length > 1) {
            this.list_cap = data.data.data;
            this.visible_cap = true;
          } else {
            this.visible_cap = false;
          }
          this.load_grafica = false;
        });
    }
  }

  viewUsersByArea() {
    this.dashboardService.getUsersByArea()
      .subscribe((data: any) => {
        if (data.data.data[0].length > 1) {
          this.usersByAreaChart.dataTable = data.data.data;
          this.usersByAreaChart.options = {
            seriesType: 'bars',
            legend: { position: 'bottom', alignment: 'start' },
          };
          this.visible_users_area = true;
        }
      });
  }

}
