import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { OrganigramaService } from 'src/app/services/organigrama.service';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-per-organigrama',
  templateUrl: './edit-per-organigrama.component.html',
  styleUrls: ['./edit-per-organigrama.component.css']
})
export class EditPerOrganigramaComponent implements OnInit {

  file: File = {} as File;
  fileName: string = '';
  typeFile: string = '';

  urlImage: string = '';
  id_nivel_selecc: number = 0;
  is_active: boolean = true;

  listItems: any[] = [];

  form = this.formBuilder.group({
    columna: [{value: '', disabled: true}],
    nivel: [{value: '', disabled: true}],
    orden: ['', [Validators.required]],
    padre: [{value: '', disabled: true}],
    nombre: ['', [Validators.required]],
    cargo: ['', [Validators.required]],
    job: [''],
    color: ['', [Validators.required]],
    area: [''],
    descripcion: ['']
  });
  
  constructor(
    public dialogRef: MatDialogRef<EditPerOrganigramaComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private organigramaService: OrganigramaService,
    private alert: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos() {
    this.form.reset({
      columna: `Columna ${this.data.nro_column}`,
      nivel: `Nivel ${this.data.level}`,
      orden: this.data.sort,
      nombre: this.data.name,
      cargo: this.data.position,
      job: this.data.job,
      area: this.data.area,
      descripcion: this.data.description,
      color: this.data.colour
    });
    if (this.data.father_id > 0) {
      this.organigramaService.getOrganigramaByIdNameFather(this.data.father_id)
        .subscribe((data_father: any) => {
          this.form.controls.padre.setValue(data_father.data.data.name);
        });
    }
    this.is_active = this.data.active ? true : false;
    //--- Mostrar imagen -----
    if (this.data.file_id > 0) {
      this.urlImage = (this.data.file.route!='' ? environment.url + '/' + this.data.file.route : '');
    }
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    const formData = new FormData();
    formData.append('sort', this.form.value.orden);
    formData.append('name', this.form.value.nombre);
    formData.append('position', this.form.value.cargo);
    formData.append('job', this.form.value.job);
    formData.append('area', this.form.value.area);
    formData.append('file', this.file);
    formData.append('description', this.form.value.descripcion);
    formData.append('colour', this.form.value.color);
    formData.append('active', this.is_active ? '1' : '0');
    
    this.organigramaService.postUpdateOrganigrama(this.data.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir imagen
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG'];
    const size = file.size;
    const maxSize = 2 * 1000000;
    let extName = this.getExtension(file.name);

    if (size > maxSize) {
      this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
    } else {
      if (extensions.indexOf(extName) > -1) {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
        }
      } else {
        this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
      }
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

  cambiarActivo(activo: boolean) {
    let param: {} = this.alert.getParamConfirm(activo ? 'desactivar' : 'activar');
    Swal.fire(param)
      .then((result: any) => {
        if (result.isConfirmed) {
          this.is_active = activo ? false : true;
        }
      });
  }

}
