import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AlertService } from 'src/app/services/alert.service';
import { BannerService } from 'src/app/services/bannerService';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-banner',
  templateUrl: './new-banner.component.html',
  styleUrls: ['./new-banner.component.css']
})
export class NewBannerComponent implements OnInit {

  public Editor = ClassicEditor;

  title: string = '';
  file: File = {} as File;
  fileName: string = '';
  typeFile: string = '';

  urlImage: string = '';
  urlVideo: string = '';

  form1 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    archivo: [''],
    descripcion: [''],
    tag: [''],
    date_: ['', [Validators.required]],
    expire: ['', [Validators.required]]
  });

  form2 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    color: ['', [Validators.required]],
    tag: ['', [Validators.required]],
    archivo: [''],
    fecha: ['', [Validators.required]],
    url: ['', [Validators.required]]
  });

  form3 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    archivo: [''],
    descripcion: [''],
    color: ['', [Validators.required]]
  });

  form4 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    tag: ['', [Validators.required]],
    archivo: [''],
    descripcion: [''],
    url: ['', [Validators.required]],
    color: ['', [Validators.required]]
  });

  form5 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    archivo: [''],
    descripcion: [''],
    url: ['', [Validators.required]],
    date_: ['', [Validators.required]],
    expire: ['', [Validators.required]],
    color: ['', [Validators.required]]
  });

  form6 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    archivo: [''],
    fecha: ['', [Validators.required]],
    url: ['', [Validators.required]]
  });

  form7 = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    archivo: [''],
    date_: ['', [Validators.required]],
    expire: ['', [Validators.required]]
  });
  
  constructor(
    public dialogRef: MatDialogRef<NewBannerComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private bannerService: BannerService,
    private alert: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.data.info) {
      this.cargarDatos();
    }
  }

  cargarDatos() {
    if (this.data.info) {
      if (this.data.type_id == 1) {
        this.form1.reset({
          titulo: this.data.info.title,
          descripcion: this.data.info.description,
          //tag: this.data.info.tag ? this.data.info.tag : "SI",
          date_: this.data.info.date_ ? moment(this.data.info.date_).format() : '',
          expire: this.data.info.expire ? moment(this.data.info.expire).format() : ''
        });
      } else if (this.data.type_id == 2) {
        this.form2.reset({
          titulo: this.data.info.title,
          url: this.data.info.url,
          color: this.data.info.colour,
          tag: this.data.info.tag,
          fecha: this.data.info.date_ ? moment(this.data.info.date_).format() : ''
        });
      } else if (this.data.type_id == 3) {
        this.form3.reset({
          titulo: this.data.info.title,
          descripcion: this.data.info.description,
          color: this.data.info.colour
        });
      } else if (this.data.type_id == 4) {
        this.form4.reset({
          titulo: this.data.info.title,
          tag: this.data.info.tag,
          descripcion: this.data.info.description,
          url: this.data.info.url,
          color: this.data.info.colour
        });
      } else if (this.data.type_id == 5) {
        this.form5.reset({
          titulo: this.data.info.title,
          descripcion: this.data.info.description,
          url: this.data.info.url,
          date_: this.data.info.date_ ? moment(this.data.info.date_).format() : '',
          expire: this.data.info.expire ? moment(this.data.info.expire).format() : '',
          color: this.data.info.colour
        });
      } else if (this.data.type_id == 6) {
        this.form6.reset({
          titulo: this.data.info.title,
          fecha: this.data.info.date_ ? moment(this.data.info.date_).format() : '',
          url: this.data.info.url
        });
      } else if (this.data.type_id == 7) {
        this.form7.reset({
          titulo: this.data.info.title,
          date_: this.data.info.date_ ? moment(this.data.info.date_).format() : '',
          expire: this.data.info.expire ? moment(this.data.info.expire).format() : ''
        });
      }
      //--- Mostrar imagen -----
      if (this.data.info.file_id > 0) {
        if (this.data.type_id == 7 && this.data.info.file.extension == 'mp4') {
          this.urlVideo = (this.data.info.file.route!='' ? environment.url + '/' + this.data.info.file.route : '');
        } else {
          this.urlImage = (this.data.info.file.route!='' ? environment.url + '/' + this.data.info.file.route : '');
        }
      }
    }
  }

  guardar() {
    if ((this.form1.invalid && this.data.type_id == 1) ||
      (this.form2.invalid && this.data.type_id == 2) ||
      (this.form3.invalid && this.data.type_id == 3) ||
      (this.form4.invalid && this.data.type_id == 4) ||
      (this.form5.invalid && this.data.type_id == 5) ||
      (this.form6.invalid && this.data.type_id == 6) ||
      (this.form7.invalid && this.data.type_id == 7)) {
      this.alert.showInfo("invalid");
      return;
    }
    if (this.fileName == '') {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();

    let formData = this.cargarFormData();

    this.bannerService.postBanner(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.alert.closeLoading();
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.closeLoading();
        this.alert.showSave('error');
      });
  }

  cargarFormData() {
    const formData = new FormData();
    formData.append('type_id', `${this.data.type_id}`);
      
    if (this.data.type_id == 1) {
      
      formData.append('title', this.form1.value.titulo);
      formData.append('file', this.file);
      formData.append('description', this.form1.value.descripcion);
      formData.append('tag', this.form1.value.tag);
      formData.append('date_', moment(this.form1.value.date_).format('YYYY-MM-DD') );
      formData.append('expire', moment(this.form1.value.expire).format('YYYY-MM-DD') );

    } else if (this.data.type_id == 2) {
      formData.append('title', this.form2.value.titulo);
      formData.append('url', this.form2.value.url);
      formData.append('file', this.file);
      formData.append('colour', this.form2.value.color);
      formData.append('tag', this.form2.value.tag);
      formData.append('date_', moment(this.form2.value.fecha).format('YYYY-MM-DD'));
    } else if (this.data.type_id == 3) {
      formData.append('title', this.form3.value.titulo);
      formData.append('file', this.file);
      formData.append('description', this.form3.value.descripcion);
      formData.append('colour', this.form3.value.color);
    } else if (this.data.type_id == 4) {
      formData.append('title', this.form4.value.titulo);
      formData.append('file', this.file);
      formData.append('description', this.form4.value.descripcion);
      formData.append('tag', this.form4.value.tag);
      formData.append('url', this.form4.value.url);
      formData.append('colour', this.form4.value.color);
    } else if (this.data.type_id == 5) {
      formData.append('title', this.form5.value.titulo);
      formData.append('file', this.file);
      formData.append('description', this.form5.value.descripcion);
      formData.append('url', this.form5.value.url);
      formData.append('date_', moment(this.form5.value.date_).format('YYYY-MM-DD'));
      formData.append('expire', moment(this.form5.value.expire).format('YYYY-MM-DD'));      
      formData.append('colour', this.form5.value.color);
    } else if (this.data.type_id == 6) {
      formData.append('title', this.form6.value.titulo);
      formData.append('file', this.file);
      formData.append('url', this.form6.value.url);
      formData.append('date_', moment(this.form6.value.fecha).format('YYYY-MM-DD'));
    } else if (this.data.type_id == 7) {
      formData.append('title', this.form7.value.titulo);
      formData.append('file', this.file);
      formData.append('date_', moment(this.form5.value.date_).format('YYYY-MM-DD'));
      formData.append('expire', moment(this.form5.value.expire).format('YYYY-MM-DD'));
    }
    return formData;
  }
 

  actualizar() {
    if ((this.form1.invalid && this.data.type_id == 1) ||
      (this.form2.invalid && this.data.type_id == 2) ||
      (this.form3.invalid && this.data.type_id == 3) ||
      (this.form4.invalid && this.data.type_id == 4) ||
      (this.form5.invalid && this.data.type_id == 5) ||
      (this.form6.invalid && this.data.type_id == 6) ||
      (this.form7.invalid && this.data.type_id == 7)) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    let formData = this.cargarFormData();
    
    this.bannerService.postUpdateBanner(this.data.info.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir imagen
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const size = file.size;
    let extName = this.getExtension(file.name);

    if (this.data.type_id == 7) {
      let maxSize = 0;
      const extensions = ['JPG','JPEG','PNG','MP4'];

      if (extensions.indexOf(extName) > -1) {
        if (['JPG','JPEG','PNG'].indexOf(extName) > -1) {
          maxSize = 4 * 1000000;
        } else if (['MP4'].indexOf(extName) > -1) {
          maxSize = 100 * 1000000;
        }
        if (size > maxSize) {
          this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
        } else {
          this.file = event.target.files[0];
          if (this.file) {
            this.fileName = this.file.name;
          }
        }
      }
    } else {
      const maxSize = 5 * 1000000;
      const extensions = ['JPG','JPEG','PNG'];
      if (size > maxSize) {
        this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
      } else {
        if (extensions.indexOf(extName) > -1) {
          this.file = event.target.files[0];
          if (this.file) {
            this.fileName = this.file.name;
          }
        } else {
          this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
        }
      }
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

  visualizarVideo(){
    let url = this.urlVideo;
    
    Swal.fire({
      html:
      `
      <div class="container-yourfriend">
          <video width="100%" height="auto" controls="" src="`+ this.urlVideo +`" autoplay playsinline>
          <source src="`+ this.urlVideo +`" type="video/mp4">
          <source src="`+ this.urlVideo +`" type="video/avi">
          <source src="`+ this.urlVideo +`" type="video/webm">
          Tu buscador no soporta este video, se recomienda utilizar otro.
        </video>
      </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: true,
      width: '500px',
      customClass: {
        container: 'video',
      },
      buttonsStyling: false,
      cancelButtonText: '<img src="../../../assets/icons/close_modal.png">',
    });
  }

}
