import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DirectorioService } from 'src/app/services/directorioService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {

  displayedColumns: string[] = ['nro', 'nombre', 'cargo', 'anexo', 'telefono', 'email', 'birthday'];
  dataSource = new MatTableDataSource();
  public url = environment.url;
  
  constructor(
    private directorioService: DirectorioService
  ) { }

  ngOnInit(): void {
    this.listar();
  }

  listar() {
    this.directorioService.getDirectorio()
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

}
