import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { OrganigramaService } from 'src/app/services/organigrama.service';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-per-organigrama',
  templateUrl: './new-per-organigrama.component.html',
  styleUrls: ['./new-per-organigrama.component.scss']
})
export class NewPerOrganigramaComponent implements OnInit {

  file: File = {} as File;
  fileName: string = '';
  typeFile: string = '';

  urlImage: string = '';

  //lisItems: any[] = [];
  listItemsNivel4: any[] = [];
  id_columna_selecc: number = 0;
  id_nivel_selecc: number = 0;

  form = this.formBuilder.group({
    columna: ['', [Validators.required]],
    nivel: ['', [Validators.required]],
    orden: ['', [Validators.required]],
    padre: [''],
    nombre: ['', [Validators.required]],
    cargo: ['', [Validators.required]],
    job: [''],
    color: ['', [Validators.required]],
    area: [''],
    descripcion: [''],
  });
  
  constructor(
    public dialogRef: MatDialogRef<NewPerOrganigramaComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private organigramaService: OrganigramaService,
    private alert: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.listarNivel4();
  }

  seleccColumna(id: number) {
    this.id_columna_selecc = id;
    this.listarNivel4();
  }

  seleccNivel(id: number) {
    this.id_nivel_selecc = id;
     this.listarNivel4();
  }

  listarNivel4() {
    this.listItemsNivel4 = [];
    if (this.id_columna_selecc > 0 && this.id_nivel_selecc > 0) {
      this.organigramaService.getOrganigrama()
        .subscribe((data:any) => {
          (data.data.data).forEach((e: any) => {
            if (e.level == 4 && e.nro_column == this.id_columna_selecc) {
              this.listItemsNivel4.push(e);
            }
          });
        });
    }
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    const formData = new FormData();
    formData.append('name', this.form.value.nombre);
    formData.append('position', this.form.value.cargo);
    formData.append('job', this.form.value.job);
    formData.append('area', this.form.value.area);
    formData.append('file', this.file);
    formData.append('description', this.form.value.descripcion);
    formData.append('sort', this.form.value.orden);
    formData.append('nro_column', this.form.value.columna);
    formData.append('level', this.form.value.nivel);
    formData.append('sort', this.form.value.orden);
    formData.append('colour', this.form.value.color);

    //===== ASIGNAR ID's PADRE =====
    if (this.form.value.padre > 0) {
      formData.append('father_id', this.form.value.padre);
    } else if (this.form.value.columna == 1) {
      formData.append('father_id', '1');
    } else if (this.form.value.columna == 2) {
      formData.append('father_id', '3');
    } else if (this.form.value.columna == 3) {
      formData.append('father_id', '4');
    } else if (this.form.value.columna == 4) {
      formData.append('father_id', '5');
    } else if (this.form.value.columna == 5) {
      formData.append('father_id', '6');
    }
    
    this.organigramaService.postOrganigrama(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir imagen
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG'];
    const size = file.size;
    const maxSize = 2 * 1000000;
    let extName = this.getExtension(file.name);

    if (size > maxSize) {
      this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
    } else {
      if (extensions.indexOf(extName) > -1) {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
        }
      } else {
        this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
      }
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

}
