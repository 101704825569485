import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { BannerComponent } from './banner/banner.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AtributoComponent } from './modulos/atributo/atributo.component';
import { ModulosComponent } from './modulos/modulos.component';
import { OrganigramaComponent } from './organigrama/organigrama.component';
import { PagesComponent } from './pages.component';
import { EmailComponent } from './usuario/email/email.component';
import { RolComponent } from './usuario/rol/rol.component';
import { UsuarioComponent } from './usuario/usuario.component';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [ AuthGuard ], // => Protege las rutas hijas
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'modulo', component: ModulosComponent },
            { path: 'modulo/:id', component: ModulosComponent },
            { path: 'modulo/:id/atributo', component: AtributoComponent },
            { path: 'banner/:type', component: BannerComponent },
            { path: 'organigrama', component: OrganigramaComponent },
            { path: 'email', component: EmailComponent },
            { path: 'usuarios', component: UsuarioComponent },
            { path: 'roles', component: RolComponent }
        ]
    }
];

@NgModule({
imports: [
  RouterModule.forChild(routes)
],
exports: [
  RouterModule
]
})
export class PagesRoutingModule { }
