<div class="boxLogin" style="background-image: url('{{imagen_fondo}}');">
    <div class="logo">
        <img src="../../../assets/images/LogoArisNegro.png" alt="">
    </div>
    <div class="loginForm">
        <p class="loginTitle font-reg">Bienvenid@ a la Zona Aris - Administrador</p>
        <p class="loginDescription font-franklin-medium">
            Este es el Panel Administrador de Aris Industrial.</p>
        <form [formGroup]="loginForm">
            <label for="user">Usuario</label>
            <input type="text" formControlName="user" placeholder="Ingrese el número de identidad">
            <label for="password">Contraseña (código del colaborador)</label>
            <input type="password" formControlName="password" placeholder="***************">
            <button class="btnLogin" (click)="login()">Ingresar</button>
            <div class="msj-error">
                <div *ngIf="msj_incompleto">-- Ingrese sus credenciales --</div>
                <div *ngIf="msj_error">-- Sus credenciales son incorrectas --</div>
                <div *ngIf="nro_error >= 3">Comuníquese con ARIS Comunicaciones, para restablecer su contraseña</div>
            </div>
        </form>
    </div>
</div>
<footer class="footer">
    <small class="font-medium">ARIS © COPYRIGHT.TODOS LOS DERECHOS RESERVADOS</small>
</footer>