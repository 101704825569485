import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  sideBarOpen = true;
  idleState = 'No iniciado!';
  //lastPing?: any = '';

  constructor(private authService: AuthService, 
    private router: Router,
    private idle: Idle) { }

  ngOnInit(): void {
    this.tiempoEspera();
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  tiempoEspera() {
    const tiempoInactiva = 120*60; // EN SEGUNDOS
    const tiempoEspera = 10;
    // sets an idle timeout of 10 seconds, for testing purposes.
    this.idle.setIdle(tiempoInactiva);
    // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(tiempoEspera);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Ya no está inactiva';
      Swal.close();
      this.reset();
    });

    this.idle.onIdleStart
      .subscribe(() => {
        this.idleState = 'Te has quedado inactivo!';
        Swal.fire({
          position: 'center',
          icon: 'info',
          text: '¡Su sesión se encuentra inactiva, se cerrará en 10 segundos!',
          showConfirmButton: false,
          heightAuto: false,
          timer: tiempoEspera*1000
        });
      });
    
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Tiempo agotado!';
      this.authService.logout();
    });
    
    /* this.idle.onTimeoutWarning
      .subscribe((countdown) => {
        this.idleState = '¡El tiempo de espera se agotará en ' + countdown + ' segundos!';
      }); */

    // sets the ping interval to 5 seconds
    /* this.keepalive.interval(5);
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date()); */
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Iniciando!';
  }

}
