import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbI } from 'src/app/interfaces/BreadcrumbI';
import { AlertService } from 'src/app/services/alert.service';
import { ModuloService } from 'src/app/services/modulo.service';
import Swal from 'sweetalert2';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
import { EditLogoComponent } from './edit-logo/edit-logo.component';
import { EditModuloComponent } from './edit-modulo/edit-modulo.component';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})
export class ModulosComponent implements OnInit {

  displayedColumns: string[] = ['nro', 'nombre', 'orden', 'activo', 'opciones'];
  dataSource = new MatTableDataSource();
  breadcrumb: Array<BreadcrumbI> = [];
  id: number = 0;
  
  constructor(private moduloService: ModuloService,
      private dialog: MatDialog,
      private alert: AlertService,
      private router: Router,
      private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        if (params['id']) {
          this.id = params['id'];
          this.listar(params['id']);
        } else {
          this.listarNivel1();
        }
      });
  }

  listarNivel1() {
    this.moduloService.getModulosNivel1()
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

  listar(id: number) {
    this.moduloService.getModulosPorNivel(id)
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
        this.mostrarBreadcrumb(id);
      });
  }

  public doFilter(strBuscar: string = '') {
    this.dataSource.filter = strBuscar.trim().toLocaleLowerCase();
  }

  agregar(id: number) {
    const dialogRef = this.dialog.open(EditModuloComponent, {
      disableClose: true,
      width: '640px',
      maxWidth: '90%',
      data: {
        id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.id == 0) {
          this.listarNivel1();
        } else {
          this.listar(this.id);
        }
      }
    });
  }

  editar(id:number) {
    this.moduloService.getModuloById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(EditModuloComponent, {
          disableClose: true,
          width: '640px',
          maxWidth: '90%',
          data: data.data.data
        });
        dialogRef.afterClosed()
          .subscribe(result => {
            if (result) {
              if (this.id == 0) {
                this.listarNivel1();
              } else {
                this.listar(this.id);
              }
            }
          });
      },
      (err) => {
        this.alert.showInfo('error');
      });
  }

  mostrarBreadcrumb(id: number) {
    this.moduloService.getBreadcrumb(id)
      .subscribe((data: any) => {
        this.breadcrumb = [];
        data.data.data.forEach((item: any) => {
          this.breadcrumb.push({
            id: item.id,
            name: item.name
          });
        });
      });
  }

  actualizarLogo(id: number) {
    this.moduloService.getModuloById(id)
      .subscribe((data: any) => {
        this.dialog.open(EditLogoComponent, {
          disableClose: true,
          maxWidth: '320px',
          data: data.data.data
        });
      });
  }

  actualizarBanner(id: number) {
    this.moduloService.getModuloById(id)
      .subscribe((data: any) => {
        this.dialog.open(EditBannerComponent, {
          disableClose: true,
          maxWidth: '320px',
          data: data.data.data
        });
      });
  }

  activarModulo(id: number) {
    let param: {} = this.alert.getParamConfirm('activar');
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.moduloService.putActivarModulo(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showUpdate('ok');
                this.listar(this.id);
              }
            });
        }
      });
  }

  desactivarModulo(id: number) {
    let param: {} = this.alert.getParamConfirm('desactivar');
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.moduloService.putDesactivarModulo(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showUpdate('ok');
                this.listar(this.id);
              }
            });
        }
      });
  }

}