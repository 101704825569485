import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-anio',
  templateUrl: './anio.component.html',
  styleUrls: ['./anio.component.css']
})
export class AnioComponent implements OnInit {

  form = this.formBuilder.group({
    nombre: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<AnioComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.form.reset({
        nombre: this.data.name
      });
    }
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    let datos = {
      name: this.form.value.nombre
    };
    
    this.generalService.putAnio(this.data.id, datos)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err: any) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

}
