import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditPasswordPerfilComponent } from 'src/app/pages/perfil/edit-password-perfil/edit-password-perfil.component';
import { CambioPasswordComponent } from 'src/app/pages/usuario/cambio-password/cambio-password.component';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  name_user: any;
  url_imagen = '../../../../assets/images/user.png';
  user: any;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.cargarInfo();
  }

  cargarInfo() {
    this.authService.getInfoAdmin()
      .subscribe((data: any) => {
        if (data.data.status !== 200) {
          Swal.fire({
              html:
              `
              <div class="container-register__exit">
                  <h2 class="Gotham"> ¡Sesión Caducada! </h2>
                  <p class="Gotham-ligth">Tu sesión ha expirado, ingresa nuevamente.</p>
              </div>
              `,
              showConfirmButton: false,
              showCloseButton: false,
              showCancelButton: true,
              focusCancel: false,
              allowOutsideClick: false,
              customClass: {
                container: 'form-register',
              },
              cancelButtonText:
                'Volver a Ingresar',
              width: '540px'
            }).then((result: any) => {
              if (result.isConfirmed) {
        
              }else{
                this.router.navigateByUrl('login');
              }
            });
          return;
        }
        this.user = data.data.data;
        if (data.data.data?.file) {
          this.url_imagen = environment.url + '/' + data.data.data.file?.route;
        }
        if (data.data.data.restart == 1) {
          this.mostrarReseteoPassword(data.data.data.id);
        }
      });
  }

  mostrarReseteoPassword(id: number) {
    // Se habilita el modal para cambio de contraseña
    this.dialog.open(CambioPasswordComponent, {
      width: '500px',
      data: { 
        user_id: id
      },
      disableClose: true
    });
  }

  cambiarPassword() {
    this.dialog.open(EditPasswordPerfilComponent, {
      disableClose: true
    });
  }

  logout() {
    this.authService.logout();
  }

}
