import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private base_url = environment.base_url;

  constructor( private http: HttpClient ) { }

  getUsers() {
    return this.http.get(`${this.base_url}/dashboard/users`);
  }

  getUsersConection() {
    return this.http.get(`${this.base_url}/dashboard/logged-accounts`);
  }

  /**
     * ==== SERVICIO EXTERNO: Tipo de cambio ====
     */
   getTipoCambio(fecha: string) {
    return this.http.get(`${this.base_url}/externo/tipo-cambio?fecha=${fecha}`);
  }

  /**
   * ==== SERVICIO EXTERNO: Capacitación por código de usuario ====
   */
  getCapacitacionByCodeUser(codigo: string) {
    return this.http.get(`${this.base_url}/dashboard/capacitacion?codigo=${codigo}`);
  }

  /**
   * ==== CONSULTA: Nro. de usuarios registrados por área o división ====
   */
  getUsersByArea() {
    return this.http.get(`${this.base_url}/dashboard/trabajadores-area`);
  }

}