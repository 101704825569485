import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { PagesComponent } from './pages.component';
import { ModulosComponent } from './modulos/modulos.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material/material.module';
import { EditModuloComponent } from './modulos/edit-modulo/edit-modulo.component';
import { AuthInterceptorService } from '../services/base/auth-interceptor.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidenavComponent } from '../shared/component/sidenav/sidenav.component';
import { EditPasswordPerfilComponent } from './perfil/edit-password-perfil/edit-password-perfil.component';
import { AtributoComponent } from './modulos/atributo/atributo.component';
import { NewAtributoComponent } from './modulos/atributo/new-atributo/new-atributo.component';
import { BannerComponent } from './banner/banner.component';
import { NewBannerComponent } from './banner/new-banner/new-banner.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { NewUsuarioComponent } from './usuario/new-usuario/new-usuario.component';
import { AsignarRolComponent } from './usuario/asignar-rol/asignar-rol.component';
import { EditPasswordComponent } from './usuario/edit-password/edit-password.component';
import { RolComponent } from './usuario/rol/rol.component';
import { NewRolComponent } from './usuario/rol/new-rol/new-rol.component';
import { AsignarSeccionComponent } from './usuario/rol/asignar-seccion/asignar-seccion.component';
import { AreaComponent } from './usuario/area/area.component';
import { NewAreaComponent } from './usuario/area/new-area/new-area.component';
import { NewCargoComponent } from './usuario/cargo/new-cargo/new-cargo.component';
import { CargoComponent } from './usuario/cargo/cargo.component';
import { EditLogoComponent } from './modulos/edit-logo/edit-logo.component';
import { EditBannerComponent } from './modulos/edit-banner/edit-banner.component';
import { OrganigramaComponent } from './organigrama/organigrama.component';
import { EditPerOrganigramaComponent } from './organigrama/edit-per-organigrama/edit-per-organigrama.component';
import { AnioComponent } from './anio/anio.component';
import { EmailComponent } from './usuario/email/email.component';
import { NewEmailComponent } from './usuario/email/new-email/new-email.component';
import { CambioPasswordComponent } from './usuario/cambio-password/cambio-password.component';
import { EditBackgroundComponent } from './edit-background/edit-background.component';
import { MetaComponent } from './meta/meta.component';
import { GraficaComponent } from './dashboard/grafica/grafica.component';
import { NewPerOrganigramaComponent } from './organigrama/new-per-organigrama/new-per-organigrama.component';
import { OnlineComponent } from './dashboard/online/online.component';

@NgModule({
  declarations: [
    PagesComponent,
    ModulosComponent,
    EditModuloComponent,
    DashboardComponent,
    SidenavComponent,
    EditPasswordPerfilComponent,
    AtributoComponent,
    NewAtributoComponent,
    BannerComponent,
    NewBannerComponent,
    DirectorioComponent,
    UsuarioComponent,
    NewUsuarioComponent,
    AsignarRolComponent,
    EditPasswordComponent,
    RolComponent,
    NewRolComponent,
    AsignarSeccionComponent,
    AreaComponent,
    NewAreaComponent,
    NewCargoComponent,
    CargoComponent,
    EditLogoComponent,
    EditBannerComponent,
    OrganigramaComponent,
    EditPerOrganigramaComponent,
    AnioComponent,
    EmailComponent,
    NewEmailComponent,
    CambioPasswordComponent,
    EditBackgroundComponent,
    MetaComponent,
    GraficaComponent,
    NewPerOrganigramaComponent,
    OnlineComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    Ng2GoogleChartsModule,

    CKEditorModule,
    
    SharedModule,
    MaterialModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class PagesModule { }
