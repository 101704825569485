<h5 md-dialog-title id="modal-title" *ngIf="!data">Nuevo Cargo</h5>
<h5 md-dialog-title id="modal-title" *ngIf="data">Editar Cargo</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field>
            <input matInput placeholder="Nombre"
                formControlName="nombre"
                required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" required></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="!data">Guardar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="data">Actualizar</button>
</mat-dialog-actions>