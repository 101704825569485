<mat-toolbar>
    <mat-toolbar-row>
      <div class="row mr-2 ml-auto">
        <ul class="row m-0 align-items-center">
          <li>
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              class="user d-flex align-items-center">
              <div class="img-user" style="background-image: url({{url_imagen}});"></div>
              <span class="text-user">{{ user?.name }}</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="cambiarPassword()">
                <mat-icon>password</mat-icon>
                Contraseña
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                Cerrar sesión
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
</mat-toolbar>
<div class="panel-header"></div>