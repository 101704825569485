<h5 md-dialog-title id="modal-title">Editar nombre de año</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field>
            <input matInput
                formControlName="nombre"
                placeholder="Nombre">
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>