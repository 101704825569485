<h5 md-dialog-title id="modal-title">Editar Logo</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <input type="file" class="file-input"
            (change)="onFileSelected($event)"
            accept="jpg, jpeg, png"
            #fileUpload>
        <div class="file-upload">
            {{fileName || "Seleccione imagen"}}
            <button mat-mini-fab color="primary" class="upload-btn"
                    (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
            <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
            <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>