import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

    private base_url = environment.base_url;

    constructor(private http: HttpClient) { }

    getBackgroundByType(type_id: number) {
        return this.http.get(`${ this.base_url }/background-images/${type_id}?expand=file`);
    }

    postBackground(formData: FormData, type_id: number) {
        return this.http.post(`${ this.base_url }/background-images/${type_id}`, formData);
    }

}