import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor() { }

  getMenu(nro_section: number) {
    return of(menu[nro_section]);
  }
}

const menu = [
      [
          {
            title: 'Dashboard',
            iconName: 'dashboard',
            route: 'dashboard'
          }
      ],
      [
          {
            title: 'Módulos',
            iconName: 'apps',
            route: 'modulo'
          }
      ],
      [
          {
            title: 'Módulos Nivel 1',
            iconName: 'apps'
          },
          {
            title: 'Siempre informado',
            iconName: 'apps'
          },
          {
            title: 'Cultura Aris',
            iconName: 'apps'
          },
          {
            title: 'Mejora continua y 5S',
            iconName: 'apps'
          },
          {
            title: 'Agenda',
            iconName: 'apps'
          }
      ],
      [
          {
            title: 'Usuarios',
            iconName: 'person'
          },
          {
            title: 'Áreas',
            iconName: 'event_seat'
          },
          {
            title: 'Cargos',
            iconName: 'work'
          }
      ],
      [
          {
            title: 'Roles',
            iconName: 'apps'
          }
      ]
    
];