import { AfterViewInit, Component, Input } from '@angular/core';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent implements AfterViewInit {

  @Input() data: any;

  public usersOnlineChart: GoogleChartInterface = {
    chartType: GoogleChartType.ComboChart,
    dataTable: [],
    options: {
      seriesType: 'bars',
      series: {6: {type: 'line'}}
    },
  };

  constructor() { }

  ngAfterViewInit(): void {
    //this.changing.subscribe(v = > {
      //do whatever with v.. has the data received, like:
      //this.changingChart.data = v.data
      this.usersOnlineChart.dataTable = this.data;
      this.usersOnlineChart.options = {
        seriesType: 'bars',
        legend: { position: 'bottom', alignment: 'start' },
      };
    //}
  }

}
