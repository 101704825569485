import { Component, Input, OnInit } from '@angular/core';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';

@Component({
  selector: 'app-grafica',
  templateUrl: './grafica.component.html',
  styleUrls: ['./grafica.component.css']
})
export class GraficaComponent implements OnInit {

  @Input() data: any;

  capacitacionByUserChart: GoogleChartInterface = {
    chartType: GoogleChartType.ComboChart,
    dataTable: [],
    options: {
      seriesType: 'bars',
      series: {6: {type: 'line'}}
    },
  };

  constructor() { }

  ngOnInit(): void {
    this.capacitacionByUserChart.dataTable = this.data;
    this.capacitacionByUserChart.options = {
      seriesType: 'bars',
      legend: { position: 'bottom', alignment: 'start' },
    };
  }

}
