import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import { AuthService } from 'src/app/services/auth.service';
import { BackgroundService } from 'src/app/services/background.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private tokenKeyLocalStorage = `${environment.APP_VERSION}${environment.APP_TOKEN_KEY}`;

  hide = true;
  validacion: string = '';
  imagen_fondo: string = '';
  url = environment.url;

  tokenInvalid: string = 'pad' + moment().format('DMYY');
  msj_incompleto = false;
  msj_error = false;
  nro_error: number = 0;

  loginForm = this.fb.group(
    {
      user: ['', [Validators.required]],
      password: ['', [Validators.required]]
    }
  );

  constructor( 
    private router: Router, 
    private fb: FormBuilder,
    private authService: AuthService,
    private backgroundService: BackgroundService
  ) { }

  ngOnInit() {
    this.obtenerImagenFondo();
  }

  login() {
    if (this.loginForm.invalid) {
      this.msj_error = false;
      this.nro_error = 0;
      this.msj_incompleto = true;
      setTimeout(() => {
        this.msj_incompleto = false;
      }, 4000);
      return;
    }

    this.authService.postLogin(this.loginForm.value)
      .subscribe((resp: any) => {
        if (resp.data.status == 200) {
          localStorage.removeItem(this.tokenInvalid);
          localStorage.setItem(this.tokenKeyLocalStorage, resp.data.data.tk);
          this.router.navigate(['/dashboard']);
        } else {
          this.msj_incompleto = false;
          this.msj_error = true;
          if (localStorage.getItem(this.tokenInvalid)) {
            let x: any = localStorage.getItem(this.tokenInvalid);
            this.nro_error = x ? x : 0;
          }
          if (this.nro_error < 3) {
            this.nro_error++;
            localStorage.setItem(this.tokenInvalid, `${this.nro_error}`);
          }
          setTimeout(() => {
            this.msj_error = false;
            this.nro_error = 0;
          }, 12000);
        }
      },
      err => {
        if(err.error)
          Swal.fire('Error', err.error.message, 'error');
      });
  }

  obtenerImagenFondo() {
    this.backgroundService.getBackgroundByType(1)
      .subscribe((data: any) => {
        this.imagen_fondo = this.url + '/' + data.data.data.file.route;
      });
  }

}