import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParametroService {

  private base_url = environment.base_url;
  private _refresh = new Subject<void>();

  constructor(private http: HttpClient) { }

  getParametros():Observable<any> {
    return this.http.get(`${ this.base_url }/parameter`);
  }

  getParametrosByGrupo(grupo: string):Observable<any> {
    return this.http.get(`${ this.base_url }/parameter?group=${ grupo }`);
  }

  getParametroById(id: number) {
    return this.http.get(`${ this.base_url }/parameter/${ id }`);
  }

  postParametro(formData: FormData) {
    return this.http.post<any>(`${ this.base_url }/parameter`, formData);
  }

  putParametro(id:number, data:any) {
    return this.http.put(`${ this.base_url }/parameter/${ id }`, data)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  deleteParametro(id:number) {
    return this.http.delete(`${ this.base_url }/parameter/${ id }`)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

}