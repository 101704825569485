<h5 class="card-title">
    Directorio
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-dark">Contactos</li>
    </ul>
</h5>
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="nro">
        <th mat-header-cell *matHeaderCellDef> Nro. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="foto">
        <th mat-header-cell *matHeaderCellDef> Foto </th>
        <td mat-cell *matCellDef="let element">
            <div class="vistaPrevia" *ngIf="element.file.route!=''">
                <img src="{{ url + '/' + element.file.route}}">
            </div>
        </td>
    </ng-container> -->
    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name + ' ' + element.last_name}} </td>
    </ng-container>
    <ng-container matColumnDef="cargo">
        <th mat-header-cell *matHeaderCellDef> Cargo </th>
        <td mat-cell *matCellDef="let element"> {{element.pos}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef> Área </th>
        <td mat-cell *matCellDef="let element"> {{element.area}} </td>
    </ng-container> -->
    <ng-container matColumnDef="anexo">
        <th mat-header-cell *matHeaderCellDef> Anexo </th>
        <td mat-cell *matCellDef="let element"> {{element.anexo}} </td>
    </ng-container>
    <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef> Teléfono </th>
        <td mat-cell *matCellDef="let element"> {{element.cell}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="birthday">
        <th mat-header-cell *matHeaderCellDef> Cumpleaños </th>
        <td mat-cell *matCellDef="let element"> {{element.birthday}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>