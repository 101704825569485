<h5 class="card-title">
    Cargos
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-muted">Usuarios</li>
        <li class="text-dark">Cargos</li>
    </ul>
</h5>
<div class="bar-options">
    <div class="row">
        <div class="col text-end">
            <button mat-raised-button class="btn-action1"
                (click)="agregar()">
                <mat-icon matSuffix>add</mat-icon>
                Nuevo Cargo
            </button>
        </div>
    </div>
</div>
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="nro">
        <th mat-header-cell *matHeaderCellDef> Nro. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>
    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef>Opciones</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="btn-blue"
                    (click)="editar(element.id)"
                    matTooltip="Editar">
                <mat-icon>edit</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>