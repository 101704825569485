<h5 class="card-title">
    Organigrama
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-dark">Personal</li>
    </ul>
</h5>
<div class="bar-options">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <input matInput placeholder="Buscar..."
                    (keyup)="doFilter($event)"
                    autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col text-end">
            <button mat-raised-button class="btn-action1"
                (click)="agregar()">
                <mat-icon matSuffix>add</mat-icon>
                Nuevo Item
            </button>
        </div>
    </div>
</div>
<div class="xtable">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="columna">
            <th mat-header-cell *matHeaderCellDef> Columna </th>
            <td mat-cell *matCellDef="let element;"> {{element.nro_column ? 'Columna ' + element.nro_column : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="nivel">
            <th mat-header-cell *matHeaderCellDef> Nivel </th>
            <td mat-cell *matCellDef="let element;"> Nivel {{element.level}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="cargo">
            <th mat-header-cell *matHeaderCellDef> Cargo </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef> Área </th>
            <td mat-cell *matCellDef="let element"> {{element.area}} </td>
        </ng-container> -->
        <ng-container matColumnDef="activo">
            <th mat-header-cell *matHeaderCellDef> Activo </th>
            <td mat-cell *matCellDef="let element">
                <span class="badge bg-success" *ngIf="element.active == 1">Activo</span>
                <span class="badge bg-danger" *ngIf="element.active == 0">Inactivo</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button class="btn-blue"
                        (click)="editar(element.id)"
                        matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button 
                        class="color-danger"
                        (click)="eliminar(element.id)"
                        matTooltip="Eliminar">
                    <mat-icon>delete</mat-icon>
                </button>
                <!---button mat-icon-button 
                        *ngIf="element.level == 4 || element.level == 5"
                        class="color-danger"
                        (click)="eliminar(element.id)"
                        matTooltip="Eliminar">
                    <mat-icon>delete</mat-icon>
                </button--->
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>