<h5 md-dialog-title id="modal-title" *ngIf="rol_id==0">Asignar rol</h5>
<h5 md-dialog-title id="modal-title" *ngIf="rol_id > 0">Actualizar asignación de rol</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field>
            <input matInput placeholder="Nombres"
                formControlName="nombre"
                required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rol</mat-label>
            <mat-select formControlName="rol" required  (selectionChange)="roleChange($event.value)" >
                <mat-option *ngFor="let itemRol of listRoles; let i = index" value="{{itemRol.id}}">
                    {{itemRol.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="currentAccess" class="role-list">
            <label>Menú del rol</label>
            <div innerHtml="{{currentAccess}}"></div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="rol_id==0">Guardar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="rol_id > 0">Actualizar</button>
</mat-dialog-actions>