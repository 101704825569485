import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CargoService {

    private base_url = environment.base_url;
    private _refresh = new Subject<void>();

    constructor(private http: HttpClient) { }

    getCargos() {
        return this.http.get(`${ this.base_url }/position`);
    }
    
    getCargoById(id:number) {
        return this.http.get(`${ this.base_url }/position/${ id }`);
    }

    postCargo(formData: FormData ) {
        return this.http.post(`${ this.base_url }/position`, formData);
    }

    putCargo(id:number, data:any) {
        return this.http.put(`${ this.base_url }/position/${ id }`, data)
          .pipe(
            tap(() => {
              this._refresh.next();
            })
          );
      }

    deleteCargo(id:number) {
        return this.http.delete(`${ this.base_url }/position/${ id }`)
        .pipe(
            tap(() => {
            this._refresh.next();
            })
        );
    }
}