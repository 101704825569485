<h5 md-dialog-title id="modal-title">Editar item de organigrama</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <div class="row">
            <div class="col-md-5" *ngIf="data.nro_column">
                <mat-form-field>
                    <mat-label>Nro. columna</mat-label>
                    <input matInput formControlName="columna">
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Nivel</mat-label>
                    <input matInput formControlName="nivel">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field>
                    <input matInput
                        formControlName="orden"
                        placeholder="Orden"
                        required>
                </mat-form-field>
            </div>
        </div>
        <mat-form-field *ngIf="data.level==5">
            <mat-label>Item padre</mat-label>
            <input matInput formControlName="padre">
        </mat-form-field>
        <mat-form-field>
            <input matInput
                formControlName="nombre"
                placeholder="Nombre">
        </mat-form-field>
        <div class="row">
            <div class="col-md-5">
                <mat-form-field>
                    <input matInput
                        formControlName="cargo"
                        placeholder="Cargo"
                        required>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                    <input matInput
                        formControlName="area"
                        placeholder="Área">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field style="width: 35px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="job"
                        placeholder="Cargo completo">
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 80px;">
            <div class="col-md-8">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 2Mb</mat-hint>
                </div>
            </div>
            <div class="col-md-4" *ngIf="data.level == 4 || data.level == 5">
                <mat-label>{{is_active ? '¿Desactivar?' : '¿Activar?'}}</mat-label>
                <button mat-icon-button
                        (click)="cambiarActivo(is_active)"
                        [matTooltip]="is_active ? 'Desactivar' : 'Activar'">
                    <mat-icon class="success" *ngIf="is_active">toggle_on</mat-icon>
                    <mat-icon class="danger" *ngIf="!is_active">toggle_off</mat-icon>
                </button>
            </div>
        </div>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" style="height: 80px;"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>