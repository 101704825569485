import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  url_image: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.url_image = data.url_image;
  }

  ngOnInit(): void {

  }

}
