import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganigramaService {

    private base_url = environment.base_url;
    private _refresh = new Subject<void>();

    constructor(private http: HttpClient) { }

    getOrganigrama() {
        return this.http.get(`${ this.base_url }/organization`);
    }
    
    getOrganigramaById(id:number) {
        return this.http.get(`${ this.base_url }/organization/${ id }?expand=file`);
    }

    getOrganigramaByIdNameFather(id:number) {
        return this.http.get(`${ this.base_url }/organization/${ id }?fields=name`);
    }

    postOrganigrama(formData: FormData) {
        return this.http.post(`${ this.base_url }/organization`, formData);
    }

    postUpdateOrganigrama(id:number, formData: FormData) {
        return this.http.post(`${ this.base_url }/organization/${ id }`, formData);
    }

    deleteItemOrganigrama(id: number) {
        return this.http.delete(`${ this.base_url }/organization/${ id }`)
        .pipe(
            tap(() => {
                this._refresh.next();
            })
        );
    }

}