<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
      <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
      <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>      
    </mat-drawer-content>
</mat-drawer-container>
<footer><small>ARIS © COPYRIGHT.TODOS LOS DERECHOS RESERVADOS</small></footer>