<h5 class="card-title">
    Usuarios
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-dark">Trabajadores con acceso</li>
    </ul>
</h5>
<div class="bar-options">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <input matInput placeholder="Buscar..."
                    (keyup)="doFilter($event)"
                    autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col text-end">
            <button mat-raised-button class="btn-action1"
                (click)="agregar()">
                <mat-icon matSuffix>add</mat-icon>
                Nuevo administrador
            </button>
            <button *ngIf="userData" mat-raised-button class="btn-action1"
                (click)="download()">
                <mat-icon matSuffix>import_export</mat-icon>
                Exportar registros
            </button>
        </div>
    </div>
</div>
<div class="tablex">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="nro">
            <th mat-header-cell *matHeaderCellDef> Nro. </th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef> Código </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="photo_id">
            <th mat-header-cell *matHeaderCellDef> Foto </th>
            <td mat-cell *matCellDef="let element"> 
                <button mat-button *ngIf="element.photo_id" (click)="visualizarImagen(element.id)"><mat-icon> insert_photo</mat-icon></button>
            </td>
        </ng-container>
        <ng-container matColumnDef="birth_date">
            <th mat-header-cell *matHeaderCellDef> Cumpleaños </th>
            <td mat-cell *matCellDef="let element"> {{element.birth_date}} </td>
        </ng-container>
        <ng-container matColumnDef="division">
            <th mat-header-cell *matHeaderCellDef> División </th>
            <td mat-cell *matCellDef="let element"> {{element.division}} </td>
        </ng-container>
        <ng-container matColumnDef="rol">
            <th mat-header-cell *matHeaderCellDef> Rol </th>
            <td mat-cell *matCellDef="let element"> {{element.role?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
        <ng-container matColumnDef="administrador">
            <th mat-header-cell *matHeaderCellDef> Administrador </th>
            <td mat-cell *matCellDef="let element">
                <span class="badge bg-success" *ngIf="element.administrator == 1">Administrador</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef>Opciones</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" ngClass="btn-blue">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            (click)="editar(element.id)">
                        <mat-icon class="btn-blue">edit</mat-icon>
                        Editar
                    </button>
                    <button mat-menu-item
                            (click)="asignarRol(element)"
                            *ngIf="element.id != info_admin!.id && element.administrator == 0">
                        <mat-icon class="btn-blue-light">manage_accounts</mat-icon>
                        Asignar Rol
                    </button>
                    <button mat-menu-item
                            (click)="resetear_password(element.id)"
                            *ngIf="element.id != info_admin!.id && element.administrator == 0">
                        <mat-icon class="btn-blue-light">lock_clock</mat-icon>
                        Resetear contraseña
                    </button>
                    <button mat-menu-item
                            (click)="darBaja(element.id)"
                            *ngIf="element.id != info_admin!.id && element.administrator == 0">
                        <mat-icon class="color-danger">clear</mat-icon>
                        Dar de baja
                    </button>
                    <!-- <button mat-menu-item
                            (click)="activarAdmin(element)"
                            *ngIf="element.id != info_admin!.id">
                        <mat-icon class="btn-blue-light">admin_panel_settings</mat-icon>
                        Administrador
                    </button> -->
                    <button mat-menu-item
                            (click)="eliminarAdmin(element.id)"
                            *ngIf="element.id != info_admin!.id && element.administrator == 1">
                        <mat-icon class="color-danger">delete</mat-icon>
                        Eliminar administrador
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>