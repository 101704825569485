<h5 class="card-title">
    Dashboard
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-dark">Resumen</li>
    </ul>
</h5>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs text-primary text-uppercase mb-1">
                                Tipo de cambio
                            </div>
                            <div class="mb-0 text-gray-800">Ayer: {{tc.tipoCambioAyer}}</div>
                            <div class="mb-0 text-gray-800">Hoy: {{tc.tipoCambioHoy}}</div>
                        </div>
                        <div class="col-auto">
                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs text-primary text-uppercase mb-1">
                                Usuarios
                            </div>
                            <div class="mb-0 text-gray-800">Activos: {{infoUsers?.activos}}</div>
                            <div class="mb-0 text-gray-800">Bajas: {{infoUsers?.inactivos}}</div>
                            <div class="mb-0 text-gray-800">En linea: {{infoUsers?.online.length}} <button (click)="onlineUsers()" class="btn btn-warning btn-sm" title="Ver usuarios en linea"><mat-icon>remove_red_eye</mat-icon></button></div>
                        </div>
                        <div class="col-auto">
                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="graficas row">
        <div class="col-md-6">
            <h5 class="mb-3">Usuarios conectados por unidad</h5>
            <div *ngIf="visible_user_online">
                <google-chart [data]="usersOnlineChart"></google-chart>
            </div>
            <div class="no-info" *ngIf="!visible_user_online">
                No se encontró información.
            </div>
        </div>
        <div class="col-md-6">
            <h5 class="mb-3">Capacitación por mes, de un determinado usuario</h5>
            <form [formGroup]="form">
                <div class="form-group">
                    <label for="floatingSelect">Trabajador</label>
                    <div class="col1">
                        <select formControlName="trabajador" class="form-select form-select-lg select_person">
                            <option *ngFor="let item of listTrabajador" [value]="item.codigo">
                            {{ item.codigo }} - {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col2">
                        <button mat-mini-fab color="primary" (click)="viewCapacitacionByUser()">
                            <mat-icon>query_stats</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="visible_cap">
                <app-grafica [data]="list_cap"></app-grafica>
                <!-- <google-chart [data]="capacitacionByUserChart"></google-chart> -->
            </div>
            <div class="no-info" *ngIf="!visible_cap && !load_grafica">
                No se encontró información.
            </div>
            <div class="no-info" *ngIf="load_grafica">
                Procesando...
            </div>
        </div>
    </div>
    <div class="graficas row">
        <div class="col-md-12">
            <h5 class="mb-3">Usuarios por unidad</h5>
            <div *ngIf="visible_users_area">
                <google-chart [data]="usersByAreaChart"></google-chart>
            </div>
            <div class="no-info" *ngIf="!visible_users_area">
                No se encontró información.
            </div>
        </div>
    </div>
</div>