import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

    private base_url = environment.base_url;
    private _refresh = new Subject<void>();

    constructor(private http: HttpClient) { }

    getAreas() {
        return this.http.get(`${ this.base_url }/area`);
    }
    
    getAreaById(id:number) {
        return this.http.get(`${ this.base_url }/area/${ id }`);
    }

    postArea(formData: FormData ) {
        return this.http.post(`${ this.base_url }/area`, formData);
    }

    putArea(id:number, data:any) {
        return this.http.put(`${ this.base_url }/area/${ id }`, data)
          .pipe(
            tap(() => {
              this._refresh.next();
            })
          );
      }

    deleteArea(id:number) {
        return this.http.delete(`${ this.base_url }/area/${ id }`)
        .pipe(
            tap(() => {
            this._refresh.next();
            })
        );
    }
}