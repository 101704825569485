import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.css']
})
export class NewEmailComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<NewEmailComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private generalService: GeneralService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.cargarFormulario();
    if (this.data) {
      this.cargarDatos();
    }
  }

  cargarDatos() {
    this.form.reset({
      nombre: this.data.name,
      email: this.data.email
    });
  }

  cargarFormulario() {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]]
    });
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    const formData = new FormData();
    formData.append('name', this.form.value.nombre);
    formData.append('email', this.form.value.email);

    this.generalService.postEmail(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.showSave('error');
      });
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }
    
    let datos = {
      name: this.form.value.nombre,
      email: this.form.value.email
    };
    this.generalService.putEmail(this.data.id, datos)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.dialogRef.close(true);
      },
      (err: any) => {
        this.alert.showUpdate("error");
      });
  }

}
