import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { FunctionsService } from 'src/app/services/base/functions.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { ParametroService } from 'src/app/services/parametroService';
import { PreviewFileComponent } from 'src/app/shared/component/preview-file/preview-file.component';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-atributo',
  templateUrl: './new-atributo.component.html',
  styleUrls: ['./new-atributo.component.css']
})
export class NewAtributoComponent implements OnInit {

  public Editor = ClassicEditor;

  file: File = {} as File;
  fileName: string = '';
  infoEditor: string = '';
  typeFile: string = '';
  tables: boolean = false;
  min_fecha = moment();

  urlImage: string = '';
  listaDesign: any[] = [];
  listaTipoUrl: any[] = [];

  form = this.formBuilder.group({
    titulo: ['', [Validators.required]],
    color: [''],
    url: [''],
    tipo_url: [''],
    archivo: [''],
    descripcion: [''],
    design: ['', [Validators.required]],
    fecha_expiracion: [''],
    orden: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<NewAtributoComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private moduloService: ModuloService,
    private parametroService: ParametroService,
    private alert: AlertService,
    private dialog: MatDialog,
    public fxc: FunctionsService
  ) { 

  }

  ngOnInit(): void {
    this.listParametros();
    if (this.data.info) {
      this.cargarDatos();
    }
  }

  listParametros() {
    this.parametroService.getParametrosByGrupo('DESIGN')
      .subscribe((data: any) => {
        this.listaDesign = data.data.data;
      });
    this.parametroService.getParametrosByGrupo('TYPE_URL')
      .subscribe((data: any) => {
        this.listaTipoUrl = data.data.data;
      });
  }

  seleccDesign(ev: any) {
    this.tables = false;
    //-- Se habilitára en el editor, la opción de tablas, para el tipo de diseño "Contenido tablas" --
    if (ev.value == 15) {
      this.tables = true;
    }
  }

  cargarDatos() {
    if (this.data.info) {
      this.form.reset({
        titulo: this.data.info.title,
        color: this.data.info.colour,
        url: this.data.info.url,
        tipo_url: this.data.info.type_url,
        design: this.data.info.design_id,
        descripcion: this.data.info.description,
        fecha_expiracion: this.data.info.expiration_date ? moment(this.data.info.expiration_date).format() : '',
        orden: this.data.info.sort
      });
      if (this.data.info.design_id == 15) {
        this.tables = true;
      }
      //--- Mostrar archivo -----
      if (this.data.info.file_id > 0) {
        let extName = this.data.info.file.extension.toUpperCase();
        const extImg = ['JPG','JPEG','PNG'];
        const extOffice = ['PDF','DOC','DOCX','XLS','XLSX','PPT','PPTX'];
        const extMp4 = ['MP4'];
        if (extImg.indexOf(extName) > -1) {
          this.typeFile = 'img';
          this.urlImage = (this.data.info.file.route!='' ? environment.url + '/' + this.data.info.file.route : '');
        } else if (extOffice.indexOf(extName) > -1) {
          this.typeFile = 'office';
        } else if (extMp4.indexOf(extName) > -1) {
          this.typeFile = 'mp4';
        }
      }
    }
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();

    let file_ = this.form.value.archivo ? 1 : 0;

    const formData = new FormData();
    formData.append('data[0][title]', this.form.value.titulo);
    formData.append('data[0][colour]', this.form.value.color);
    formData.append('data[0][sort]', this.form.value.orden > 0 ? this.form.value.orden : '');
    formData.append('data[0][description]', this.form.value.descripcion);
    formData.append('data[0][url]', this.form.value.url);
    formData.append('data[0][file]', this.form.value.archivo);
    formData.append(`data[0][file_]`, `${file_}`);
    formData.append('data[0][design_id]', this.form.value.design);
    if (this.form.value.tipo_url) {
      formData.append('data[0][type_url]', this.form.value.tipo_url);
    }
    if (this.form.value.fecha_expiracion != '') {
      formData.append('data[0][expiration_date]', moment(this.form.value.fecha_expiracion).format('YYYY-MM-DD'));
    }

    this.moduloService.postAtributo(this.data.mod_id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.alert.closeLoading();
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.closeLoading();
        this.alert.showSave('error');
      });
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    const formData = new FormData();
    formData.append('title', this.form.value.titulo);
    formData.append('colour', this.form.value.color);
    formData.append('description', this.form.value.descripcion);
    formData.append('url', this.form.value.url);
    formData.append('file', this.form.value.archivo);
    formData.append('design_id', this.form.value.design);
    formData.append('sort', this.form.value.orden > 0 ? this.form.value.orden : '');
    if (this.form.value.tipo_url) {
      formData.append('type_url', this.form.value.tipo_url);
    }
    if (this.form.value.fecha_expiracion != '') {
      formData.append('expiration_date', moment(this.form.value.fecha_expiracion).format('YYYY-MM-DD'));
    }
    
    this.moduloService.postUpdateAtributo(this.data.mod_id, this.data.info.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.closeLoading();
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir archivo
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG','PDF','DOC','DOCX','XLS','XLSX','PPT','PPTX','MP4'];
    // Por defecto extensión de documentos:
    let maxSize = 24 * 1000000;
    const size = file.size;
    let extName = this.getExtension(file.name);

    if (extensions.indexOf(extName) > -1) {
      if (['JPG','JPEG','PNG'].indexOf(extName) > -1) {
        maxSize = 4 * 1000000;
      } else if (['MP4'].indexOf(extName) > -1) {
        maxSize = 100 * 1000000;
      }
      if (size > maxSize) {
        this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb para el tipo de archivo seleccionado`);
      } else {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
          this.form.controls.archivo.setValue(this.file);
        }
      }
    } else {
      this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png, pdf, doc, docx, xls, xlsx, ppt y pptx, mp4`);
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  visualizarDoc(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_corta: this.data.info.file.route
    };
    dialogConfig.panelClass = 'preview-file-dialog';
    dialogConfig.width = '1000px';
    dialogConfig.height = '90vh';

    this.dialog.open(PreviewFileComponent, dialogConfig);
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

  visualizarVideo(url: any){
      Swal.fire({
        html:
        `
        <div class="container-yourfriend">
            <video width="100%" height="auto" controls="" src="`+url+`" autoplay playsinline>
            <source src="`+url+`" type="video/mp4">
            <source src="`+url+`" type="video/avi">
            <source src="`+url+`" type="video/webm">
            Tu buscador no soporta este video, se recomienda utilizar otro.
          </video>
        </div>
        `,
        showConfirmButton: false,
        showCancelButton: true,
        allowOutsideClick: true,
        width: '500px',
        customClass: {
          container: 'video',
        },
        buttonsStyling: false,
        cancelButtonText: '<img src="../../../../assets/icons/close_modal.png">',
      });
    }

}
