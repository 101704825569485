import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbI } from 'src/app/interfaces/BreadcrumbI';

import { AlertService } from 'src/app/services/alert/alert.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { PreviewFileComponent } from 'src/app/shared/component/preview-file/preview-file.component';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NewAtributoComponent } from './new-atributo/new-atributo.component';

@Component({
  selector: 'app-atributo',
  templateUrl: './atributo.component.html',
  styleUrls: ['./atributo.component.css']
})
export class AtributoComponent implements OnInit {

  displayedColumns: string[] = ['position','nro', 'titulo', 'design', 'fecha_expiracion','opciones'];
  dataSource = new MatTableDataSource();
  breadcrumb: Array<BreadcrumbI> = [];
  parent: number = 0;
  mod_id: number = 0;
  dataRows : any = [];
  
  constructor(private moduloService: ModuloService,
      private dialog: MatDialog,
      private alert: AlertService,
      private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        this.mod_id = params['id'];
        this.mostrarBreadcrumb(this.mod_id);
        this.listar();
      });
  }

  listar() {
    this.moduloService.getAtributos(this.mod_id)
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
        this.dataRows = data.data.data
      });
  }

  agregar() {
    const dialogRef = this.dialog.open(NewAtributoComponent, {
      disableClose: true,
      maxWidth: '640px',
      data: {
        mod_id: this.mod_id
      }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id:number) {
    this.moduloService.getAtributoById(this.mod_id, id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(NewAtributoComponent, {
          disableClose: true,
          maxWidth: '640px',
          data: {
            mod_id: this.mod_id,
            info: data.data.data
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.listar();
          }
        });
      },
      (err: any) => {
        this.alert.showInfo('error');
      });
  }

  eliminar(id:number) {
    let param: {} = this.alert.getParamConfirm();
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.moduloService.deleteAtributo(this.mod_id, id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showDelete('ok');
                this.listar();
              }
            });
        }
      });
  }

  mostrarBreadcrumb(id: number) {
    this.moduloService.getBreadcrumb(id)
      .subscribe((data: any) => {
        this.breadcrumb = [];
        data.data.data.forEach((item: any) => {
          this.breadcrumb.push({
            id: item.id,
            name: item.name
          });
        });
      });
  }

  mostrarArchivo(element: any) {
    //this.file_id = this.data.info.file_id;
    let extName = element.file.extension.toUpperCase();
    const extImg = ['JPG','JPEG','PNG'];
    const extOffice = ['PDF','DOC','DOCX','XLS','XLSX','PPT','PPTX'];
    if (extImg.indexOf(extName) > -1) {
      this.visualizarImagen(element.route);
    } else if (extOffice.indexOf(extName) > -1) {
      this.visualizarDoc(environment.url + '/' + element.route);
    }
  }

  visualizarDoc(url: string){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_corta: url
    };
    dialogConfig.panelClass = 'preview-file-dialog';
    dialogConfig.width = '700px';
    dialogConfig.height = '90vh';

    this.dialog.open(PreviewFileComponent, dialogConfig);
  }

  visualizarImagen(url: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: url
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }


  ordenar(itemSort: any) {
    let itemOrder=itemSort._elementRef.nativeElement.getAttribute('item-order');
    if( typeof itemOrder == "string" ) {
      itemOrder = itemOrder.split(',');
      this.alert.showLoading();

      let loadSt=0;
      for( let i in itemOrder) {
        let position : any = parseInt(i)+1
        let formData = new FormData();       
        formData.append('sort', position );
        
        this.moduloService.postUpdateAtributo(this.mod_id, itemOrder[i], formData)
          .subscribe((res: any) => {
            if (res.data.status !== 200) {
              this.alert.showErrorCustom(res.data.mensaje);
              this.alert.closeLoading();
              return;
            }

            loadSt++;
            if(loadSt==this.dataRows.length) {
              this.alert.closeLoading();
              this.listar();
            }
          },
          (err) => {
            this.alert.closeLoading();
            this.alert.showUpdate("error");
          });

          this.alert.closeLoading();
          this.listar();
      };
    }
  }

  reOrder( item : any , action : String ) {

    if( 
      this.dataRows.length >= 2 
    ) {
      this.alert.showLoading();

      let prev : any = null;
      let next : any = null;
      let current : any = null;

      for( let i in this.dataRows ) {
        let it : any = this.dataRows[i];
        if( next == null && current != null) {
          next = i;
          break;
        }
        else if(it.id == item.id) {
          current = i;
        }
        else {
          prev = i;
        }
      }

      let otherRow = action=='down' ? next : prev;   
      if(!otherRow) {
        this.alert.closeLoading();
        return;
      }

      let positionTmp = this.dataRows[current]
      this.dataRows[current] = this.dataRows[otherRow]
      this.dataRows[otherRow] = positionTmp 

      let loadSt=0;
      for( let i in this.dataRows) {
        let position : any = parseInt(i)+1
        let formData = new FormData();     
        console.log(this.dataRows[i])
        
        let file_ = this.dataRows[i].archivo ? 1 : 0;
        formData.append('sort', position );
        
        this.moduloService.postUpdateAtributo(this.dataRows[i].structure_id, this.dataRows[i].id, formData)
          .subscribe((res: any) => {
            if (res.data.status !== 200) {
              this.alert.showErrorCustom(res.data.mensaje);
              this.alert.closeLoading();
              return;
            }

            loadSt++;
            if(loadSt==this.dataRows.length) {
              this.alert.closeLoading();
              this.listar();
            }
          },
          (err) => {
            this.alert.closeLoading();
            this.alert.showUpdate("error");
          });

      }               
      
    }
  }

}
