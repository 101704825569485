import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { BackgroundService } from 'src/app/services/background.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-background',
  templateUrl: './edit-background.component.html',
  styleUrls: ['./edit-background.component.css']
})
export class EditBackgroundComponent implements OnInit {

  titulo: string = '';
  file: File = {} as File;
  fileName: string = '';
  typeFile: string = '';

  urlImage: string = '';

  form = this.formBuilder.group({
    archivo: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<EditBackgroundComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder,
    private backgroundService: BackgroundService,
    private alert: AlertService
  ) {
    this.titulo = this.data.type_id == 1 ? 'login' : (this.data.type_id == 2 ? 'cumpleaños' : 'no cumpleaños');
  }

  ngOnInit(): void {
    if (this.data.file) {
      this.urlImage = environment.url + '/' + this.data.file.route;
    }
  }

  actualizar() {
    if (!this.form.value.archivo) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    const formData = new FormData();
    formData.append('title', this.data.type_id == 1 ? 'Login': (this.data.type_id == 2 ? 'Cumple' : 'No cumple'));
    formData.append('file', this.form.value.archivo);
    formData.append('type_id', this.data.type_id);
    
    this.backgroundService.postBackground(formData, this.data.type_id)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.closeLoading();
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir archivo
   */
  onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG'];
    let maxSize = 2 * 1000000;
    const size = file.size;
    let extName = this.getExtension(file.name);

    if (extensions.indexOf(extName) > -1) {
      if (size > maxSize) {
        this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb para el tipo de archivo seleccionado`);
      } else {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
          this.form.controls.archivo.setValue(this.file);
        }
      }
    } else {
      this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

}
