import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { CargoService } from 'src/app/services/cargo.service';
import { NewCargoComponent } from './new-cargo/new-cargo.component';

@Component({
  selector: 'app-cargo',
  templateUrl: './cargo.component.html',
  styleUrls: ['./cargo.component.css']
})
export class CargoComponent implements OnInit {

  displayedColumns: string[] = ['nro', 'nombre', 'descripcion', 'opciones'];
  dataSource = new MatTableDataSource();
  id: number = 0;
  
  constructor(private cargoService: CargoService,
      private dialog: MatDialog,
      private alert: AlertService) { }

  ngOnInit(): void {
    this.listar();
  }

  listar() {
    this.cargoService.getCargos()
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

  public doFilter(strBuscar: string = '') {
    this.dataSource.filter = strBuscar.trim().toLocaleLowerCase();
  }

  agregar() {
    const dialogRef = this.dialog.open(NewCargoComponent, {
      disableClose: true,
      maxWidth: '640px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id:number) {
    this.cargoService.getCargoById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(NewCargoComponent, {
          disableClose: true,
          maxWidth: '640px',
          data: data.data.data
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.listar();
          }
        });
      },
      (err) => {
        this.alert.showInfo('error');
      });
  }

}
