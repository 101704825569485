import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private authService: AuthService,
    private router: Router ) {}

  canActivate() {
      if (this.authService.loggedIn()) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
  }
  
}