import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.css']
})
export class OnlineComponent implements OnInit {

  infoUsers : any;
  displayedColumns: string[] = [
    'nro',
    'codigo',
    'name'
];
  dataSource = new MatTableDataSource();

  constructor(
    public dialogRef: MatDialogRef<OnlineComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private dashboardService: DashboardService
  ) {
    
    
  }

  ngOnInit(): void {
    this.mostrarUsuarios();
  }

  mostrarUsuarios() {
    this.dashboardService.getUsers()
      .subscribe((data: any) => {
        this.infoUsers = data.data.data.online;
        this.dataSource = new MatTableDataSource(data.data.data.online);
      });
  }
}
