import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { NewEmailComponent } from './new-email/new-email.component';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  displayedColumns: string[] = ['nro', 'nombre', 'email', 'opciones'];
  dataSource = new MatTableDataSource();
  id: number = 0;
  nro_emails = 0;
  
  constructor(private generalService: GeneralService,
      private dialog: MatDialog,
      private alert: AlertService) { }

  ngOnInit(): void {
    this.listar();
  }

  listar() {
    this.generalService.getEmail()
      .subscribe((data:any) => {
        this.nro_emails = data.data.data.length;
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

  public doFilter(strBuscar: string = '') {
    this.dataSource.filter = strBuscar.trim().toLocaleLowerCase();
  }

  agregar() {
    const dialogRef = this.dialog.open(NewEmailComponent, {
      disableClose: true,
      maxWidth: '640px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id:number) {
    this.generalService.getEmailById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(NewEmailComponent, {
          disableClose: true,
          maxWidth: '640px',
          data: data.data.data
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.listar();
          }
        });
      },
      (err: any) => {
        this.alert.showInfo('error');
      });
  }

  eliminar(id: number) {
    if (this.nro_emails == 1) {
      this.alert.showInfoCustom('No se puede eliminar, como mínimo debe exitir un email receptor, se sugiere que edite la información');
    } else {
      let param: {} = this.alert.getParamConfirm();
      Swal.fire(param)
        .then((result) => {
          if (result.isConfirmed) {
            this.generalService.deleteEmail(id)
              .subscribe((data: any) => {
                if (data.data.status !== 200) {
                  this.alert.showWarningCustom(data.data.mensaje);
                  return;
                } else {
                  this.alert.showDelete('ok');
                  this.listar();
                }
              });
          }
        });
    }
  }

}
