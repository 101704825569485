import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-asignar-rol',
  templateUrl: './asignar-rol.component.html',
  styleUrls: ['./asignar-rol.component.css']
})
export class AsignarRolComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  listRoles: any;
  rol_id: number = 0;
  currentAccess: any;

  constructor(
    public dialogRef: MatDialogRef<AsignarRolComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder:FormBuilder, 
    private usuarioService: UsuarioService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.cargarFormulario();
    this.cargarRoles();
    if (this.data.role) {
      this.rol_id = this.data.role.id;
      this.form.reset({
        nombre: this.data.name,
        rol: this.rol_id
      });
    }
  }


  cargarAccesos( rolID : any = 0 ) {

    rolID = rolID == 0 ? this.rol_id : rolID
    
    if(rolID == 0) {
      this.currentAccess = null
    }
    else {
      this.currentAccess = ''      
      this.usuarioService.getPermisosRolByUser(rolID)
        .subscribe((resp: any) => {
          if(resp.data.data) {
              this.currentAccess += '<ul class="menu-tree">';
              this.accesosHijos( resp.data.data )
              this.currentAccess += '</ul>';
          }
        });
    }    
  }


  accesosHijos( arr : any ) {

    arr.forEach( (it : any)  => {
      if(it.activo==0) {
        return
      }

      this.currentAccess += '<li><span>'+it.name+'</span>';
      
      if(it.children) {
        this.currentAccess += '<ul class="menu-subtree">';
        this.accesosHijos( it.children )
        this.currentAccess += '</ul>';
      }

      this.currentAccess += '</li>';
      
    });
    
  }
  
  roleChange( value : String ) {
    this.cargarAccesos(value)
  }

  cargarFormulario() {
    this.form = this.formBuilder.group({
      nombre: [{ value: `${this.data.name} ${this.data.last_name}`, disabled: true}],
      rol: ['', [Validators.required]]
    });
  }

  cargarRoles() {
    this.usuarioService.getRoles()
      .subscribe((data: any) => {
        this.listRoles = data.data.data;

        this.cargarAccesos()
      });
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    const formData = new FormData();
    formData.append('user_id', this.data.id);
    formData.append('role_id', this.form.value.rol);

    this.usuarioService.postAsignarRol(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.showSave('error');
      });
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    let datos = {
      role_id: this.form.value.rol
    };

    this.usuarioService.putAsignarRol(datos, this.data.id)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showUpdate('ok');
          this.dialogRef.close(true);
        }
      },
      (err) => {
        this.alert.showUpdate('error');
      });
  }

}
