<h5 md-dialog-title id="modal-title">Actualizar contraseña</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field>
            <mat-label>Contraseña:</mat-label>
            <input matInput formControlName="password"
                required
                type="password"
                maxlength="20" minlength="5">
            <mat-hint>Mínimo de carácteres: 7</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Repetir contraseña:</mat-label>
            <input matInput formControlName="repeat_password"
                required
                type="password"
                maxlength="20">
            <mat-hint>Mínimo de carácteres: 7</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" (click)="salir()">Salir</button>
    <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>
<div class="mensajeRpta">
    <div *ngIf="mensajeRpta!='ok' && mensajeRpta!=''">** {{mensajeRpta}} **</div>
    <div *ngIf="mensajeRpta=='ok'" class="rpta-ok">** Se actualizó correctamente, se procedera a cerrar sesión e ingrese nuevamente con su nueva contraseña **</div>
</div>