import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.scss']
})
export class PreviewFileComponent implements OnInit {

  url_file: string = "";
  url_corta: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.url_corta = data.url_corta;
  }

  ngOnInit(): void {
    this.previewFile();
  }

  previewFile(){
    this.url_file = environment.url + '/' + this.url_corta;
  }

}
