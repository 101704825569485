<h5 md-dialog-title id="modal-title" >Usuarios en Linea</h5>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="nro">
            <th mat-header-cell *matHeaderCellDef> Nro. </th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef class="ps-3 pe-3"> Código </th>
            <td mat-cell *matCellDef="let element" class="ps-3 pe-3"> {{element.codigo}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>