import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseI } from '../interfaces/ResponseI';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private base_url = environment.base_url;
  private _refresh = new Subject<void>();

  constructor( private http: HttpClient) { }
  
  postUsuario(formData: FormData) {
    return this.http.post(`${ this.base_url }/user`, formData);
  }

  postUpdateUsuario(id: number, data: any) {
    return this.http.post(`${ this.base_url }/user/${id}`, data);
  }

  getUsuarios() {
    return this.http.get(`${ this.base_url }/user?expand=role`);
  }

  getCodeUsuarios() {
    return this.http.get(`${ this.base_url }/user?fields=codigo,name&type_user=1`);
  }

  getUsuarioById(id: number) {
    return this.http.get(`${ this.base_url }/user/${id}?expand=file`);
  }

  updateInactivo(id: number) {
    return this.http.delete(`${ this.base_url }/user/${id}`)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  resetearPassword(id: number) {
    return this.http.put(`${ this.base_url }/user/${id}/password-restart`, null)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  deleteUsuario(id:number) {
    return this.http.delete(`${ this.base_url }/user/${ id }`)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  //=====================================
  //============ PERMISOS ===============
  //=====================================
  postPermisosByRol(formData: FormData) {
    return this.http.post(`${ this.base_url }/role-permission`, formData);
  }

  getPermisosRolByUser(id: number) {
    return this.http.get(`${ this.base_url }/role-permission/${id}`);
  }

  //===============================
  //========== ROLES ==============
  //===============================

  getRoles() {
    return this.http.get<ResponseI>(`${ this.base_url }/role`);
  }

  getRolById(id:number):Observable<ResponseI> {
    return this.http.get<ResponseI>(`${ this.base_url }/role/${ id }`);
  }

  postRol(formData: FormData) {
    return this.http.post(`${ this.base_url }/role`, formData);
  }

  putRol(id:number, data:any) {
    return this.http.put(`${ this.base_url }/role/${ id }`, data)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  deleteRol(id:number) {
    return this.http.delete(`${ this.base_url }/role/${ id }`)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }
  
  //====================================
  //====== ASIGNAR ROL A USUARIO =======
  //====================================
  postAsignarRol(formData: FormData) {
    return this.http.post(`${ this.base_url }/user-role`, formData);
  }

  putAsignarRol(datos: any, id: number) {
    return this.http.put(`${ this.base_url }/user-role/${id}`, datos);
  }

  //===============================
  //========= PASSWORD ============
  //===============================
  cambiarPasswordUser(formData: FormData, id: number) {
    return this.http.post(`${ this.base_url }/user/${id}/cambio-contrasena`, formData);
  }

  //-- Cambio de contraseña de mi perfil admin --
  cambiarMiPasswordPerfil(datos: any) {
    return this.http.put(`${ this.base_url }/user/update-password-admin`, datos);
  }

  //====================================
  //========= ADMINISTRADOR ============
  //====================================
  activarAdmin(id: number) {
    return this.http.post(`${ this.base_url }/user/${id}/activar-admin`, null);
  }

  desactivarAdmin(id: number) {
    return this.http.post(`${ this.base_url }/user/${id}/desactivar-admin`, null);
  }

}
