import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.css']
})
export class CambioPasswordComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  mensajeRpta = '';
  user_id: number = 0;

  constructor(private formBuilder: FormBuilder,
      private authService: AuthService,
      private alert: AlertService,
      public dialogRef: MatDialogRef<CambioPasswordComponent>, 
      @Inject(MAT_DIALOG_DATA) data: any) {
    this.user_id = data.user_id;
  }

  ngOnInit(): void {
    this.cargarForm();
  }

  cargarForm() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      repeat_password: ['', [Validators.required]],
    });
  }

  actualizar() {
    if (this.form.value.password.length < 7 || this.form.value.password.length < 7) {
      this.mensajeRpta = 'Ingrese correctamente los datos solicitados';
      setTimeout(() => {
        this.mensajeRpta = '';
      }, 4000);
      return;
    }
    if (this.form.value.password != this.form.value.repeat_password) {
      this.form.reset();
      this.mensajeRpta = 'La contraseñas ingresadas no coinciden';
      setTimeout(() => {
        this.mensajeRpta = '';
      }, 4000);
      return;
    }

    let data = {
      password: this.form.value.password
    };

    this.authService.putResetUpdatePassword(data, this.user_id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        this.form.reset();
        this.mensajeRpta = 'ok';
        setTimeout(() => {
          this.salir();
        }, 5000);
      });
  }

  salir() {
    this.dialogRef.close();
    this.authService.logout();
  }
  
}
