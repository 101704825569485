import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenKeyLocalStorage = `${environment.APP_VERSION}${environment.APP_TOKEN_KEY}`;
  private base_url = environment.base_url;
  private _refresh = new Subject<void>();

  constructor( private http: HttpClient, private router: Router ) { } /* , private cookieService: CookieService */

  postLogin(formData: FormData) {
    return this.http.post(`${ this.base_url }/user/signin-admin`, formData);
  }

  getInfoAdmin() {
    return this.http.get(`${ this.base_url }/user/info-admin?expand=file`);
  }

  loggedIn() {
    return !!localStorage.getItem(this.tokenKeyLocalStorage);
  }

  logout() {
    localStorage.removeItem(this.tokenKeyLocalStorage);
    this.router.navigateByUrl('/login');
  }

  /* validar() {
    return this.http.get(`${ this.base_url }/personal/usuario/signin`);
  } */

  signUpUser(formData: FormData) {
    return this.http.post<any>(this.base_url + '/personal/usuario/signup', formData);
  }


  signInUser(formData: FormData) {
    return this.http.post<any>(this.base_url + '/personal/usuario/signin', formData);
  }

  getToken() {
    return localStorage.getItem(this.tokenKeyLocalStorage);
  }

  putResetUpdatePassword(data: any, user_id: number) {
    return this.http.put(`${ this.base_url }/user/${user_id}/update-password`, data)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

}
