import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AfterViewInit, Component, Inject, Injectable} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { UsuarioService } from 'src/app/services/usuario.service';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[] = [];
  item: NodoI[] = [];
  id: string = '';
}

export interface NodoI {
  id: number,
  level: number,
  name: string
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id: string = '';
  item: NodoI[] = [];
  level: number = 0;
  expandable: boolean = false;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor(
    public dialogRef: MatDialogRef<AsignarSeccionComponent>, 
    @Inject(MAT_DIALOG_DATA) public dataRol: any, 
    private moduloService: ModuloService,
    private usuarioService: UsuarioService) {
    this.initialize();
  }

  initialize() {
    this.usuarioService.getPermisosRolByUser(this.dataRol.id)
      .subscribe((resp: any) => {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(resp.data.data, 0);

        // Notify the change.
        this.dataChange.next(data);
      });
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = value;

      if (value.children != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value.children, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'app-asignar-seccion',
  templateUrl: './asignar-seccion.component.html',
  styleUrls: ['./asignar-seccion.component.css'],
  providers: [ChecklistDatabase],
})
export class AsignarSeccionComponent {

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  constructor(
      public dialogRef: MatDialogRef<AsignarSeccionComponent>, 
      @Inject(MAT_DIALOG_DATA) public dataRol: any, 
      private _database: ChecklistDatabase,
      private usuarioService: UsuarioService,
      private alert: AlertService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
      this.loadData();
    });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === []; //--------------------'';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  actualizar() {
    if (this.checklistSelection.selected.length == 0) {
      this.alert.showWarningCustom('Debe seleccionar como mínimo 1 módulo');
      return;
    }

    let par: any = [];

    this.checklistSelection.selected.forEach(e => {
      let mod: any = e.item;
      if (par.indexOf(mod.id) == -1) {
        par.push(mod.id);
      }

      let parent: TodoItemFlatNode | null = this.getParentNode(e);
      while (parent) {
        let nod: any = parent.item;
        
        // Recorrido de los parents no seleccionados
        if (par.indexOf(nod.id) == -1) {
          par.push(nod.id);
        }
        // Item seleccionado
        parent = this.getParentNode(parent);
      }
    });

    const formData = new FormData();
    formData.append('role_id', this.dataRol.id);
    
    par.forEach((e: any) => {
      formData.append('modules[]', e);
    });
    
    this.usuarioService.postPermisosByRol(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.showUpdate("error");
      });
  }

  loadData() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      let x: any = this.treeControl.dataNodes[i].item;
      if (!x.children && x.activo == 1) {
        this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }
  }
}