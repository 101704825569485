import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { AsignarRolComponent } from './asignar-rol/asignar-rol.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { NewUsuarioComponent } from './new-usuario/new-usuario.component';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  displayedColumns: string[] = [
    'nro',
    'codigo',
    'nombre',
    'photo_id',
    'birth_date',
    'division',
    'rol',
    'email',
    'administrador',
    'opciones'
];
  dataSource = new MatTableDataSource();
  nro: number = 0;
  info_admin: any;
  userData: any;

  constructor(private dialog: MatDialog,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.authService.getInfoAdmin()
      .subscribe((data: any) => {
        this.info_admin = data.data.data;
      });
    this.listar();
  }

  listar() {
    this.usuarioService.getUsuarios()
      .subscribe((data:any) => {
        this.userData=data.data.data
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

  public doFilter(ev: any) {
    let strBuscar = ev.target.value;
    this.dataSource.filter = strBuscar.trim().toLocaleLowerCase();
  }

  agregar() {
    const dialogRef = this.dialog.open(NewUsuarioComponent, {
      disableClose: true,
      maxWidth: '640px',
      data: 'new-admin'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id: number) {
    this.usuarioService.getUsuarioById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(NewUsuarioComponent, {
          disableClose: true,
          data: data.data.data,
          maxWidth: '640px'
        });
        dialogRef.afterClosed()
          .subscribe(result => {
            if (result) {
              this.listar();
            }
          });
      },
      (err) => {
        this.alert.showInfo('error');
      });
  }

  darBaja(id:number) {
    let param: {} = this.alert.getParamConfirm("dar de baja al usuario");
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.usuarioService.updateInactivo(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showInfoCustom("Se ha dado de baja al usuario correctamente");
                this.listar();
              }
            });
        }
      });
  }

  asignarRol(info: any) {
    const dialogRef = this.dialog.open(AsignarRolComponent, {
      disableClose: true,
      data: info,
      maxWidth: '320px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  cambiarPassword(id: number) {
    const dialogRef = this.dialog.open(EditPasswordComponent, {
      disableClose: true,
      data: {
        'id': id
      }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }
  
  visualizarImagen(user_id : any) {

    this.usuarioService.getUsuarioById(user_id)
      .subscribe((data: any) => {
        let urlImage = (data.data.data.file.route!='' ? environment.url + '/' + data.data.data.file.route : '');

        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          url_image: urlImage
        };
        dialogConfig.panelClass = 'preview-img-dialog';
        dialogConfig.width = '400px';
        dialogConfig.height = '400px';
    
        this.dialog.open(PreviewComponent, dialogConfig);
      })    
  }

  activarAdmin(data: any) {
    let act = 'ACTIVAR';
    if (data.administrator == 1) {
      act = 'DESACTIVAR';
    }
    let param: {} = this.alert.getParamConfirm(`${act} como administrador`);
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          if (data.administrator == 1) {
            this.usuarioService.desactivarAdmin(data.id)
              .subscribe((data: any) => {
                if (data.data.status !== 200) {
                  this.alert.showWarningCustom(data.data.mensaje);
                  return;
                } else {
                  this.alert.showInfoCustom("Se desactivo como administrador al usuario");
                  this.listar();
                }
              });
          } else {
            this.usuarioService.activarAdmin(data.id)
              .subscribe((data: any) => {
                if (data.data.status !== 200) {
                  this.alert.showWarningCustom(data.data.mensaje);
                  return;
                } else {
                  this.alert.showInfoCustom("Se asigno al usuario como administrador");
                  this.listar();
                }
              });
          }
        }
      });
  }

  resetear_password(id: number) {
    let param: {} = this.alert.getParamConfirm(`resetear contraseña`);
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.usuarioService.resetearPassword(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showInfoCustom("Se áctivo el reseteo de contraseña");
                this.listar();
              }
            });
        }
      });
  }

  eliminarAdmin(user_id: number) {
    let param: {} = this.alert.getParamConfirm(`eliminar el administrador`);
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.usuarioService.deleteUsuario(user_id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showInfoCustom("Se eliminó correctamente el administrador");
                this.listar();
              }
            });
        }
      });
  }

  download() {
    console.log('this.userData',this.userData);

    let csvContent = "data:text/csv;charset=utf-8,";
    let row = null
    if(this.userData) {
      this.userData = [Object.keys(this.userData[0])].concat(this.userData)

      this.userData.forEach( (it : any) => {        
        it.role = it.role ? it.role.name : '';
        csvContent += Object.values(it).toString()+"\n"
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      var now = new Date()
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "aris_usuarios_" + now.getTime() + ".csv");
      document.body.appendChild(link); 
      link.click();
    }
  }

  convertToCSV(arr : any) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    return array.map(it => { console.log(Object.values(it));
      return Object.values(it).toString()
    }).join('\n')
  }

}
