import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {

  file: File = {} as File;
  fileName: string = '';
  urlImage: string = '';
  form = this.formBuilder.group({
    archivo: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<EditBannerComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private moduloService: ModuloService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.urlImage = (this.data.fbanner?.route!='' ? environment.url + '/' + this.data.fbanner?.route : '');
    }
  }

  /**
   * Subir imagen
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG'];
    const size = file.size;
    const maxSize = 5 * 1000000;
    let extName = this.getExtension(file.name);

    if (size > maxSize) {
      this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
    } else {
      if (extensions.indexOf(extName) > -1) {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
        }
      } else {
        this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
      }
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();
    
    const formData = new FormData();
    formData.append('file', this.file);
    
    this.moduloService.postBanner(this.data.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.alert.closeLoading();
        this.dialogRef.close(true);
      },
      (err: any) => {
        this.alert.closeLoading();
        this.alert.showUpdate("error");
      });
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

}
