<h5 class="card-title">
    Atributos
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-muted">
          <a routerLink="/modulo" class="text-muted">Módulos</a>
        </li>
        <li *ngFor="let item of breadcrumb; let i = index" class="text-muted">
            <a routerLink="/modulo/{{item.id}}" class="text-muted" *ngIf="i < breadcrumb.length - 1">{{item.name}}</a>
            <a class="text-dark" *ngIf="i == breadcrumb.length - 1">Atributos: {{item.name}}</a>
        </li>
    </ul>
</h5>
<div class="bar-options">
    <div class="row">
        <div class="col text-end">
            <button #itemSort mat-raised-button class="item-sort btn-action1"
                (click)="ordenar(itemSort)">
                <mat-icon matSuffix>reorder</mat-icon>
                Confirmar Orden
            </button>
            &nbsp;
            <button mat-raised-button class="btn-action1"
                (click)="agregar()">
                <mat-icon matSuffix>add</mat-icon>
                Nuevo Atributo
            </button>
        </div>
    </div>
</div>
<div class="tablex">
    <table mat-table [dataSource]="dataSource" class="table-sortable">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon style="pointer-events: all;"
              cdkDragHandle>reorder</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="nro">
            <th mat-header-cell *matHeaderCellDef>Nro. </th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="titulo" >
            <th mat-header-cell *matHeaderCellDef> Título </th>
            <td mat-cell *matCellDef="let element"> 
                <span class="item-id d-none">{{element.id}}</span>
                {{element.title}}
            </td>
        </ng-container>
        <ng-container matColumnDef="design">
            <th mat-header-cell *matHeaderCellDef> Diseño </th>
            <td mat-cell *matCellDef="let element"> {{element.design}} </td>
        </ng-container>
        <ng-container matColumnDef="fecha_expiracion">
            <th mat-header-cell *matHeaderCellDef> Fecha de expiración </th>
            <td mat-cell *matCellDef="let element">{{element.expiration_date | date: 'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button class="btn-blue"
                        (click)="editar(element.id)"
                        matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button class="color-danger"
                        (click)="eliminar(element.id)"
                        matTooltip="Eliminar">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; index"></tr>
    </table>
</div>