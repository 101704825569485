import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

    private base_url = environment.base_url;
    private _refresh = new Subject<void>();

    constructor(private http: HttpClient) { }

    /**
     * ====== Nombre del año ======
     */
    getAnioById(id: number) {
        return this.http.get(`${ this.base_url }/anio/${ id }`);
    }

    putAnio(id:number, data: any) {
        return this.http.put(`${ this.base_url }/anio/${ id }`, data)
          .pipe(
            tap(() => {
              this._refresh.next();
            })
          );
    }

    /**
     * ======= Email ========
     */
    getEmail() {
        return this.http.get(`${ this.base_url }/email`);
    }
    
    getEmailById(id:number) {
        return this.http.get(`${ this.base_url }/email/${ id }`);
    }

    postEmail(formData: FormData ) {
        return this.http.post(`${ this.base_url }/email`, formData);
    }

    putEmail(id:number, data:any) {
        return this.http.put(`${ this.base_url }/email/${ id }`, data)
          .pipe(
            tap(() => {
              this._refresh.next();
            })
          );
    }

    deleteEmail(id:number) {
        return this.http.delete(`${ this.base_url }/email/${ id }`)
        .pipe(
            tap(() => {
            this._refresh.next();
            })
        );
    }

    /**
     * ======= META ========
     */
    getMetaById(id: number) {
      return this.http.get(`${ this.base_url }/meta/${id}`);
    }

    putMeta(id:number, data:any) {
      return this.http.put(`${ this.base_url }/meta/${ id }`, data)
        .pipe(
          tap(() => {
            this._refresh.next();
          })
        );
    }
}