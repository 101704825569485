import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'src/app/shared/loading-dialog/loading-dialog.component';
import Swal, { SweetAlertResult } from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private dialog: MatDialog) { }

  showInfo(tipo: string) {
    switch (tipo) {
      case 'error':
        Toast.fire({
          icon: 'error',
          title: 'Error al obtener los datos'
        });
        break;
      case 'warning':
        Toast.fire({
          icon: 'warning',
          title: 'No se pudo obtener la información'
        });
        break;
      case 'invalid':
        Toast.fire({
          icon: 'info',
          title: 'Debe completar la información correctamente'
        });
        break;
    }
  }

  showWarningCustom(mensaje: string) {
    Toast.fire({
      icon: 'warning',
      title: mensaje
    });
  }

  showErrorCustom(mensaje: string) {
    Toast.fire({
      icon: 'error',
      title: mensaje
    });
  }

  showInfoCustom(mensaje: string) {
    Toast.fire({
      icon: 'info',
      title: mensaje
    });
  }

  showSave(tipo: string) {
    switch (tipo) {
      case 'error':
        Toast.fire({
          icon: 'error',
          title: 'Error al guardar los datos'
        });
        break;
      case 'warning':
        Toast.fire({
          icon: 'warning',
          text: 'No pudimos guardar los datos ingresados'
        });
        break;
      case 'ok':
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Registro exitoso...',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500
        });
        break;
    }
  }

  showUpdate(tipo: string) {
    switch (tipo) {
      case 'error':
        Toast.fire({
          icon: 'error',
          text: 'Error al actualizar los datos'
        });
        break;
      case 'warning':
        Toast.fire({
          icon: 'warning',
          text: 'No pudimos actualizar los datos ingresados'
        });
        break;
      case 'ok':
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Actualización exitosa...',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500
        });
        break;
    }
  }

  showDelete(tipo: string) {
    switch (tipo) {
      case 'error':
        Toast.fire({
          icon: 'error',
          text: 'Error al eliminar'
        });
        break;
      case 'warning':
        Toast.fire({
          icon: 'warning',
          text: 'No pudimos eliminarlo'
        });
        break;
      default:
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Se eliminó correctamente',
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500
        });
        break;
    }
  }

  getParamConfirm(accion = 'eliminar') {
    //let btn = accion[0].toUpperCase() + accion.slice(1);
    return {
      text: `¿Está seguro de ${accion}?`,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46a367',
      cancelButtonColor: '#f44336',
      confirmButtonText: `Confirmar!`,
      cancelButtonText: 'Cancelar',
      heightAuto: false
    };
  }

  loading(title: string) {
    Swal.fire({
      title,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
  }

  showLoading(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    //this.loadingDialog = 
    this.dialog.open(LoadingDialogComponent, dialogConfig);
  }

  closeLoading(){
    this.dialog.closeAll();
  }
}
