<h5 class="card-title">
    Banner
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-dark">
          <a routerLink="/banner/{{type_id}}" class="text-muted">{{name_banner}}</a>
        </li>
    </ul>
</h5>
<div class="bar-options" *ngIf="type_id != 7 || (type_id==7 && nroItemsPopupHome==0)">
    <div class="row">
        <div class="col text-end">
            <button #itemSort mat-raised-button class="item-sort btn-action1"
                (click)="ordenar(itemSort)">
                <mat-icon matSuffix>reorder</mat-icon>
                Confirmar Orden
            </button>
            &nbsp;
            <button mat-raised-button class="btn-action1"
                (click)="agregar()">
                <mat-icon matSuffix>add</mat-icon>
                Nuevo Banner
            </button>
        </div>
    </div>
</div>
<table mat-table 
    class="table-sortable"
    #table
    [dataSource]="dataSource">
>
    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon style="pointer-events: all;"
          cdkDragHandle>reorder</mat-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="nro">
        <th mat-header-cell *matHeaderCellDef> Nro. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>
    <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element" >
            <span class="item-id d-none">{{element.id}}</span>
            {{element.title}} 
        </td>
    </ng-container>
    <ng-container matColumnDef="date_">
        <th mat-header-cell *matHeaderCellDef> Fecha Visualización </th>
        <td mat-cell *matCellDef="let element"> {{element.date_}} </td>
    </ng-container>
    <ng-container matColumnDef="expire">
        <th mat-header-cell *matHeaderCellDef> Fecha Expiración </th>
        <td mat-cell *matCellDef="let element"> {{element.expire}} </td>
    </ng-container>
    <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef> Mostrar Dialogo</th>
        <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
    </ng-container>
    <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef> Opciones </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="btn-blue"
                    (click)="editar(element.id)"
                    matTooltip="Editar">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button class="color-danger"
                    (click)="eliminar(element.id)"
                    matTooltip="Eliminar">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr 
        mat-row 
        *matRowDef="let row; columns: displayedColumns;" 
    ></tr>
</table>