<h5 md-dialog-title id="modal-title">Editar imagen de fondo de {{titulo}}</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <div class="row">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 2Mb</mat-hint>
                </div>
                <div class="imagen" *ngIf="urlImage!=''"><img src="{{urlImage}}"></div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>