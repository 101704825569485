import { Component, Input, OnInit } from '@angular/core';
import { NavItemI } from 'src/app/interfaces/NavItemI';
import { SidebarService } from 'src/app/services/base/sidebar.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { NavigationEnd, Router, Event } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ParametroService } from 'src/app/services/parametroService';
import { MatDialog } from '@angular/material/dialog';
import { AnioComponent } from 'src/app/pages/anio/anio.component';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { EditBackgroundComponent } from 'src/app/pages/edit-background/edit-background.component';
import { BackgroundService } from 'src/app/services/background.service';
import { MetaComponent } from 'src/app/pages/meta/meta.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidenavComponent implements OnInit {
  
  menuItems: any[] = [];
  currentPage: string = '';
  section: number = 0;
  listLinksBanner: any[] = [];

  public currentUrl = new BehaviorSubject<string>('');

  @Input() item: NavItemI = {} as NavItemI;

  constructor( private sidebarService: SidebarService,
      public router: Router,
      private parametroService: ParametroService,
      private alert: AlertService,
      private generalService: GeneralService,
      private backgroundService: BackgroundService,
      private dialog: MatDialog) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);

        const url_split = event.urlAfterRedirects.split('/');
        this.section = url_split.indexOf('dashboard') > -1 ? 1 :
                          url_split.indexOf('modulo') > -1 ? 2 :
                            url_split.indexOf('banner') > -1 ? 3 : 
                              url_split.indexOf('organigrama') > -1 ? 4 :
                                url_split.indexOf('anio') > -1 ? 7 :
                                  url_split.indexOf('email') > -1 ? 8 :
                                    url_split.indexOf('usuarios') > -1 ? 5 : 
                                      url_split.indexOf('roles') > -1 ? 6 : 1;
      }
    });
  }

  ngOnInit(): void {
    this.mostrarMenu(0);
    this.mostrarLinksBanner();
  }

  ChangeScreen(opc: string) {
    this.currentPage = opc;
  }

  onItemSelected(item: NavItemI) {
    if (item.children && item.children.length) {
      item.expanded = !item.expanded;
    }
  }

  notCollapse(item: NavItemI) {
    if (item.children && item.children.length) {
      this.menuItems.forEach((e, i) => {
        if (e.children && e!=item) {
          e.expanded = false;
        }
      });
    }
  }

  mostrarMenu(nro_section: number) {
    this.sidebarService.getMenu(nro_section)
      .subscribe((items: any) => {
        this.section = nro_section;
        this.menuItems = items;
      });
  }

  mostrarLinksBanner() {
    this.parametroService.getParametrosByGrupo('BANNER')
      .subscribe((data: any) => {
        this.listLinksBanner = data.data.data;
      });
  }

  editNameYear() {
    const id_anio = 1;
    this.generalService.getAnioById(id_anio)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        this.dialog.open(AnioComponent, {
          disableClose: true,
          width: '540px',
          data: data.data.data
        });
      },
      (err: any) => {
        this.alert.showInfo('error');
      });
  }

  editMeta() {
    const id_meta = 1;
    this.generalService.getMetaById(id_meta)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        this.dialog.open(MetaComponent, {
          disableClose: true,
          width: '320px',
          data: data.data.data
        });
      },
      (err: any) => {
        this.alert.showInfo('error');
      });
  }

  editBackground(type_id: number) {
    this.backgroundService.getBackgroundByType(type_id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        this.dialog.open(EditBackgroundComponent, {
          disableClose: true,
          width: '320px',
          data: data.data.data
        });
      },
      (err: any) => {
        this.alert.showInfo('error');
      });
  }
}