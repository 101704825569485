<h5 md-dialog-title id="modal-title" *ngIf="!data.info">Nuevo Atributo</h5>
<h5 md-dialog-title id="modal-title" *ngIf="data.info">Editar Atributo</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field [style.width.%]="47" style="margin-right: 3%">
            <mat-label>Tipo de diseño</mat-label>
            <mat-select formControlName="design" required (selectionChange)="seleccDesign($event)">
                <mat-option *ngFor="let item of listaDesign; let i = index" [value]="item.value">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [style.width.%]="32" style="margin-right: 3%">
            <mat-label>Fecha de expiración</mat-label>
            <input matInput [matDatepicker]="picker1"
                formControlName="fecha_expiracion"
                [min]="min_fecha"
                readonly>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field [style.width.%]="15">
            <input matInput placeholder="Orden"
                formControlName="orden"
                (keypress)="fxc.numberOnly($event)"
                maxlength='2'>
        </mat-form-field>
        <mat-form-field [style.width.%]="90" style="margin-right: 3%">
            <input matInput
                formControlName="titulo"
                placeholder="Titulo">
        </mat-form-field>
        <mat-form-field [style.width.%]="7">
            <input matInput
                formControlName="color"
                placeholder="Color"
                type="color">
        </mat-form-field>
        <mat-form-field [style.width.%]="15" style="margin-right: 3%">
            <mat-label>Tipo Url</mat-label>
            <mat-select formControlName="tipo_url">
                <mat-option *ngFor="let item of listaTipoUrl" [value]="item.value">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [style.width.%]="82">
            <input matInput
                formControlName="url"
                placeholder="Url">
        </mat-form-field>
        <input type="file" name="archivopdf"
            class="file-input"
            (change)="onFileSelected($event)"
            accept="jpg, jpeg, png, pdf, doc, docx, xls, xlsx, ppt, pptx, mp4"
            #fileUpload>
        <div class="file-upload">
            {{fileName || "Seleccione archivo"}}
            <button mat-mini-fab color="primary" class="upload-btn"
                    (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
            <button mat-button *ngIf="data.info?.file && typeFile == 'office'" (click)="visualizarDoc()">Ver Documento</button>
            <button mat-button *ngIf="urlImage!='' && typeFile == 'img'" (click)="visualizarImagen()">Ver Imagen</button>
            <button mat-button *ngIf="data.info?.file && typeFile == 'mp4'" (click)="visualizarVideo(data.info?.file)">Ver Video</button>
            <br>
            <mat-hint>
                Formato de archivo: jpg|jpeg|png (Peso max. 4Mb). 
                pdf|doc|docx|xls|xlsx|ppt|pptx (Peso max. 25Mb). 
                mp4 (Peso max. 100Mb)
            </mat-hint><br><br>
        </div>
        <ckeditor [editor]="Editor"
            [config]="{toolbar: ['bold', 'italic', '|', 'BulletedList']}"
            formControlName="descripcion"
            *ngIf="!tables">
        </ckeditor>
        <ckeditor [editor]="Editor"
            [config]="{
                        toolbar: {
                            items: [
                                'alignment', '|',
                                'bold', 'italic', '|',
                                'bulletedList', '|',
                                'insertTable'
                            ]
                        },
                        shouldNotGroupWhenFull: true
                    }"
            formControlName="descripcion"
            style="height: 400px;"
            *ngIf="tables">
        </ckeditor>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="!data.info">Guardar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="data.info">Actualizar</button>
</mat-dialog-actions>