import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DirectorioService {

    private base_url = environment.base_url;

    constructor(private http: HttpClient) { }

    getDirectorio() {
        return this.http.get(`${ this.base_url }/directory?expand=file,pos`);
    }

}