<h5 md-dialog-title id="modal-title" *ngIf="opc_new">Nuevo Usuario Administrador</h5>
<h5 md-dialog-title id="modal-title" *ngIf="!opc_new">Editar Usuario</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <input matInput placeholder="Nombre"
                        formControlName="nombre"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Nro. de documento"
                        formControlName="nro_documento"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Cumpleaños" formControlName="birth_date" [matDatepicker]="birth_date" />
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="birth_date"></mat-datepicker-toggle>
                    <mat-datepicker #birth_date></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="División"
                        formControlName="division"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Subdivisión"
                        formControlName="subdivision"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Posición"
                        formControlName="position"
                        required>
                </mat-form-field>


            </div>
            <div class="col-md-6">
                <mat-form-field >
                    <input matInput placeholder="Código"
                        formControlName="codigo"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Email"
                        formControlName="email"
                        required>
                </mat-form-field>
                <div *ngIf="!opc_new">
                    <input type="file" class="file-input"
                        (change)="onFileSelected($event)"
                        accept="jpg, jpeg, png"
                        #fileUpload>
                    <div class="file-upload">
                        {{fileName || "Seleccione imagen"}}
                        <button mat-mini-fab color="primary" class="upload-btn"
                                (click)="fileUpload.click()">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                        <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="opc_new">Guardar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="!opc_new">Actualizar</button>
</mat-dialog-actions>