import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FunctionsService {

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    decimalOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode!=44 && charCode!=46) {
            return false;
        }
        return true;
    }
}