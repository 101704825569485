import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ResponseI } from '../interfaces/ResponseI';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  private base_url = environment.base_url;
  private _refresh = new Subject<void>();

  constructor(private http: HttpClient) { }

  getModulos():Observable<any> {
    return this.http.get<ResponseI>(`${ this.base_url }/structure`);
  }

  getModulosNivel1() {
    return this.http.get(`${ this.base_url }/structure?lev=1`);
  }

  getModulosPorNivel(id: number) {
    return this.http.get<ResponseI>(`${ this.base_url }/structure/${id}/sub?expand=ch`);
  }

  getModuloById(id:number):Observable<ResponseI> {
    return this.http.get<ResponseI>(`${ this.base_url }/structure/${ id }?expand=flogo,fbanner`);
  }

  putModulo(id:number, data: any) {
    return this.http.put(`${ this.base_url }/structure/${ id }`, data)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  putActivarModulo(id:number) {
    return this.http.put(`${ this.base_url }/structure/${ id }/activar`, null)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  putDesactivarModulo(id:number) {
    return this.http.put(`${ this.base_url }/structure/${ id }/desactivar`, null)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  /**
   * SUBMODULOS
   */
  getBreadcrumb(mod_id: number):Observable<any> {
    return this.http.get<ResponseI>(`${ this.base_url }/structure/${ mod_id }/detail-parent`);
  }

  postSubModulo(id:number, formData: FormData) {
    return this.http.post(`${ this.base_url }/structure/${ id }/sub`, formData);
  }

  getModulosSubDetalle() {
    return this.http.get(`${ this.base_url }/structure/detail`);
  }

  /**
   * ATRIBUTOS
   */
  getAtributos(mod_id: number):Observable<any> {
    return this.http.get<ResponseI>(`${ this.base_url }/structure/${ mod_id }/complement?expand=file,design`);
  }

  getAtributoById(mod_id: number, id:number):Observable<ResponseI> {
    return this.http.get<ResponseI>(`${ this.base_url }/structure/${ mod_id }/complement/${ id }?expand=file`);
  }

  postAtributo(mod_id: number, formData: FormData ): Observable<any> {
    return this.http.post(`${ this.base_url }/structure/${ mod_id }/complement`, formData);
  }

  postUpdateAtributo(mod_id: number, id: number, formData: FormData ): Observable<any> {
    return this.http.post(`${ this.base_url }/structure/${ mod_id }/complement/${ id }`, formData);
  }

  deleteAtributo(mod_id: number, id:number) {
    return this.http.delete(`${ this.base_url }/structure/${ mod_id }/complement/${ id }`)
      .pipe(
        tap(() => {
          this._refresh.next();
        })
      );
  }

  postLogo(mod_id: number, formData: FormData) {
    return this.http.post(`${ this.base_url }/structure/${ mod_id }/logo`, formData);
  }

  postBanner(mod_id: number, formData: FormData) {
    return this.http.post(`${ this.base_url }/structure/${ mod_id }/banner`, formData);
  }

}