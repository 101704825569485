import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { BannerService } from 'src/app/services/bannerService';
import Swal from 'sweetalert2';
import { NewBannerComponent } from './new-banner/new-banner.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  displayedColumns: string[] = [];
  dataRows : any = [];
  dataSource = new MatTableDataSource();
  dataList : any;
  type_id: number = 0;
  name_banner: string = '';
  nroItemsPopupHome = 0;
  
  constructor(private bannerService: BannerService,
      private dialog: MatDialog,
      private alert: AlertService,
      private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        this.type_id = params['type'];
        this.name_banner = this.type_id == 1 ? 'Principal' :
                            this.type_id == 2 ? 'Siempre informado' :
                              this.type_id == 3 ? 'Cultura Aris' : 
                                this.type_id == 4 ? 'Programa ARIS' :
                                  this.type_id == 5 ? 'Info ARIS' :
                                    this.type_id == 6 ? 'Agenda' : 'Popup Home';

        if( this.type_id == 1 ) {
          this.displayedColumns=['position','nro', 'titulo', 'date_','expire','tag','opciones']
        }
        else if( this.type_id == 5 || this.type_id == 7 ) {
          this.displayedColumns=['position','nro', 'titulo', 'date_','expire','opciones']
        }
        else {
          this.displayedColumns=['position','nro', 'titulo', 'date_','tag','opciones']
        }
        this.listar();

        
      });
  }

  ordenar(itemSort: any) {
    let itemOrder=itemSort._elementRef.nativeElement.getAttribute('item-order');
    if( typeof itemOrder == "string" ) {
      itemOrder = itemOrder.split(',');
      this.alert.showLoading();

      let loadSt=0;
      for( let i in itemOrder) {
        let position : any = parseInt(i)+1
        let formData = new FormData();       
        formData.append('position', position );
        
        this.bannerService.postUpdateBanner(itemOrder[i], formData)
          .subscribe((res: any) => {
            if (res.data.status !== 200) {
              this.alert.showErrorCustom(res.data.mensaje);
              this.alert.closeLoading();
              return;
            }

            loadSt++;
            if(loadSt==this.dataRows.length) {
              this.alert.closeLoading();
              this.listar();
            }
          },
          (err) => {
            this.alert.closeLoading();
            this.alert.showUpdate("error");
          });

          this.alert.closeLoading();
          this.listar();
      };
    }
  }

  listar() {
    this.bannerService.getBanners(this.type_id)
      .subscribe((data:any) => {
        this.dataSource = data.data.data;
        if (this.type_id == 7) {
          this.nroItemsPopupHome = data.data.data.length;
        }

        this.dataRows = data.data.data;
        this.dataList = data.data.data;
        // if(data.data.data.length>0) {
        //   data.data.data.forEach( (it : any) => {
        //     this.dataRows = data.data.data;
        //   });
        // }

      });
  }

  agregar() {
    const dialogRef = this.dialog.open(NewBannerComponent, {
      disableClose: true,
      width: '540px',
      data: {
        type_id: this.type_id,
        name_banner: this.name_banner
      }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id:number) {
    this.bannerService.getBannerById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(NewBannerComponent, {
          disableClose: true,
          width: '540px',
          data: {
            type_id: this.type_id,
            name_banner: this.name_banner,
            info: data.data.data
          }
        });
        dialogRef.afterClosed()
          .subscribe((result: any) => {
            if (result) {
              this.listar();
            }
          });
      },
      (err) => {
        this.alert.showInfo('error');
      });
  }

  eliminar(id:number) {
    let param: {} = this.alert.getParamConfirm();
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.bannerService.deleteBanner(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showDelete('ok');
                this.listar();
              }
            });
        }
      });
  }

}
