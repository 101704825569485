<h5 md-dialog-title id="modal-title">Asignar secciones</h5>
<mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="checklistSelection.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node)">{{node.item.name}}</mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)"
                      [indeterminate]="descendantsPartiallySelected(node)"
                      (change)="todoItemSelectionToggle(node)">{{node.item.name}}</mat-checkbox>
      </mat-tree-node>
  </mat-tree>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
  <button mat-raised-button class="primary" (click)="actualizar()">Actualizar</button>
</mat-dialog-actions>