<h5 class="card-title">
    Módulos
    <ul class="breadcrumb fw-bold fs-base my-1">
        <li class="text-muted">
          <a routerLink="/modulo" class="text-muted" *ngIf="id > 0">Módulos</a>
          <a class="text-dark" *ngIf="id == 0">Módulos</a>
        </li>
        <li *ngFor="let item of breadcrumb; let i = index;" class="text-muted">
            <a routerLink="/modulo/{{item.id}}" class="text-muted" *ngIf="i <= breadcrumb.length - 2">{{item.name}}</a>
            <a class="text-dark" *ngIf="i == breadcrumb.length - 1">{{item.name}}</a>
        </li>
    </ul>
</h5>
<div class="tablex">
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="nro">
        <th mat-header-cell *matHeaderCellDef> Nro. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>
    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="orden">
        <th mat-header-cell *matHeaderCellDef> Orden </th>
        <td mat-cell *matCellDef="let element">
            {{element.level > 2 ? element.sort : ''}}
        </td>
    </ng-container>
    <ng-container matColumnDef="activo">
        <th mat-header-cell *matHeaderCellDef> Activo </th>
        <td mat-cell *matCellDef="let element">
            <span class="badge bg-success" *ngIf="element.active == 1">Activo</span>
            <span class="badge bg-danger" *ngIf="element.active == 0">Inactivo</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef> Opciones </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="btn-green"
                    (click)="agregar(element.id)"
                    *ngIf="(element.level == 2 || element.level == 3) && element.active == 1"
                    matTooltip="Nuevo Sub-Módulo">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-icon-button class="btn-green"
                    [routerLink]="['/modulo', element.id]"
                    *ngIf="((element.ch && element.level > 1) || element.level == 1) &&  element.active == 1"
                    matTooltip="Ver Sub-Módulos">
                <mat-icon>space_dashboard</mat-icon>
            </button>
            <button mat-icon-button class="btn-blue-light"
                    [routerLink]="['/modulo', element.id, 'atributo']"
                    *ngIf="(element.level > 2 || element.parent_id == 6) && element.active == 1"
                    matTooltip="Atributos">
                <mat-icon>fact_check</mat-icon>
            </button>
            <button mat-icon-button class="btn-green"
                    (click)="activarModulo(element.id)"
                    *ngIf="element.level >= 3 && element.active == 0"
                    matTooltip="Activar">
                <mat-icon class="btn-blue-light">thumb_up</mat-icon>
            </button>
            <button mat-icon-button 
                    *ngIf="element.active == 1"
                    [matMenuTriggerFor]="menu"
                    ngClass="btn-blue">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="editar(element.id)"
                        >
                    <mat-icon class="btn-blue-light">edit</mat-icon>
                    Editar
                </button>
                <button mat-menu-item
                        (click)="actualizarLogo(element.id)"
                        *ngIf="element.level == 2">
                    <mat-icon class="btn-blue-light">photo</mat-icon>
                    Logo
                </button>
                <button mat-menu-item
                        (click)="actualizarBanner(element.id)"
                        *ngIf="element.level == 2">
                    <mat-icon class="btn-blue-light">vrpano</mat-icon>
                    Banner
                </button>
                <button mat-menu-item
                        (click)="desactivarModulo(element.id)"
                        *ngIf="element.level >= 3 && element.active == 1">
                    <mat-icon class="color-danger">thumb_down</mat-icon>
                    Desactivar
                </button>
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>