import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-edit-password-perfil',
  templateUrl: './edit-password-perfil.component.html',
  styleUrls: ['./edit-password-perfil.component.css']
})
export class EditPasswordPerfilComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  public formSubmitted = false;
  hide = true;

  constructor(
    public dialogRef: MatDialogRef<EditPasswordPerfilComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder: FormBuilder, 
    private usuarioService: UsuarioService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.cargarFormulario();
  }

  cargarFormulario() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      password_repeat: ['', [Validators.required]]
    });
  }

  actualizar() {
    if (this.form.value.password != this.form.value.password_repeat) {
      this.alert.showWarningCustom("Las contraseñas no coinciden");
      return;
    }
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    let datos = {
      password: this.form.value.password
    };

    this.usuarioService.cambiarMiPasswordPerfil(datos)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.showSave('error');
      });
  }

}
