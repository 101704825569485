<h5 md-dialog-title id="modal-title">Nuevo item de organigrama</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <div class="row">
            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Nro. columna</mat-label>
                    <mat-select formControlName="columna" required (selectionChange)="seleccColumna($event.value)">
                        <mat-option value="1">Columna 1</mat-option>
                        <mat-option value="2">Columna 2</mat-option>
                        <mat-option value="3">Columna 3</mat-option>
                        <mat-option value="4">Columna 4</mat-option>
                        <mat-option value="5">Columna 5</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Nivel</mat-label>
                    <mat-select formControlName="nivel" required (selectionChange)="seleccNivel($event.value)">
                        <mat-option value="2">Nivel 2</mat-option>
                        <mat-option value="3">Nivel 3</mat-option>
                        <mat-option value="4">Nivel 4</mat-option>
                        <mat-option value="5">Nivel 5</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field>
                    <input matInput
                        formControlName="orden"
                        placeholder="Orden"
                        required>
                </mat-form-field>
            </div>
        </div>
        <mat-form-field *ngIf="id_nivel_selecc==5">
            <mat-label>Seleccione el item padre</mat-label>
            <mat-select formControlName="padre">
                <mat-option *ngFor="let item of listItemsNivel4" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput
                formControlName="nombre"
                placeholder="Nombre"
                required>
        </mat-form-field>
        <div class="row">
            <div class="col-md-5">
                <mat-form-field>
                    <input matInput
                        formControlName="cargo"
                        placeholder="Cargo"
                        required>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                    <input matInput
                        formControlName="area"
                        placeholder="Área">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field style="width: 35px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="job"
                        placeholder="Cargo completo">
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 80px;">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 2Mb</mat-hint>
                </div>
            </div>
        </div>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" style="height: 80px;"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()">Guardar</button>
</mat-dialog-actions>