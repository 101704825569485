import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { PreviewFileComponent } from './component/preview-file/preview-file.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PreviewComponent } from './component/preview/preview.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoadingDialogComponent,
    PreviewFileComponent,
    PreviewComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgxDocViewerModule
  ]
})
export class SharedModule { }
