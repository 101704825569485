<mat-nav-list>
    <div class="logo">
      <div class="txt-logo"><img src="../../../../assets/images/LogoArisNegro.png" alt=""></div>
    </div>
    <div class="menu-modulo">
      <div class="menu-principal">
        <ul class="nav flex-column">
          <li>
            <button routerLink="/dashboard"  
                [ngClass]="{active: section == 1}"
                matTooltip="Dashboard"
                matTooltipPosition="below">
              <img src="../../../../assets/images/dashboard.png">
            </button>
          </li>
          <li>
            <button routerLink="/modulo"
                [ngClass]="{active: section == 2}"
                matTooltip="Módulos"
                matTooltipPosition="below">
              <img src="../../../../assets/images/modules.png">
            </button>
          </li>
          <li>
            <button [ngClass]="{active: section == 3}"
                matTooltip="Banners"
                matTooltipPosition="below"
                [matMenuTriggerFor]="menuBanner">
              <img src="../../../../assets/images/banner.png">
            </button>
            <mat-menu #menuBanner="matMenu">
              <button mat-menu-item *ngFor="let item of listLinksBanner" [routerLink]="['/banner', item.value]">
                <span>{{item.name}}</span>
              </button>
            </mat-menu>
          </li>
          <li>
            <button routerLink="/organigrama"
                [ngClass]="{active: section == 4}"
                matTooltip="Organigrama"
                matTooltipPosition="below">
              <img src="../../../../assets/images/directorio.png">
            </button>
          </li>
          <li>
            <button (click)="editNameYear()"
                matTooltip="Nombre año"
                matTooltipPosition="below">
              <img src="../../../../assets/images/anio.png">
            </button>
          </li>
          <li>
            <button (click)="editMeta()"
                matTooltip="Meta"
                matTooltipPosition="below">
              <img src="../../../../assets/images/meta.png">
            </button>
          </li>
          <li>
            <button [ngClass]="{active: section == 3}"
                matTooltip="Imagen de fondo"
                matTooltipPosition="below"
                [matMenuTriggerFor]="menuDashboard">
              <img src="../../../../assets/images/imagen.png">
            </button>
            <mat-menu #menuDashboard="matMenu">
              <button mat-menu-item (click)="editBackground(1)">
                <span>Login</span>
              </button>
              <button mat-menu-item (click)="editBackground(2)">
                <span>Cumpleaños</span>
              </button>
              <button mat-menu-item (click)="editBackground(3)">
                <span>No cumpleaños</span>
              </button>
            </mat-menu>
          </li>
          <li>
            <button [routerLink]="['/email']"
                [ngClass]="{active: section == 8}"
                matTooltip="Emails"
                matTooltipPosition="below">
              <img src="../../../../assets/images/email.png">
            </button>
          </li>
          <li>
              <button [routerLink]="['/usuarios']"
                  [ngClass]="{active: section == 5}"
                  matTooltip="Usuarios"
                  matTooltipPosition="below">
                <img src="../../../../assets/images/usuario.png">
              </button>
          </li>
          <li>
              <button routerLink="/roles"
                  [ngClass]="{active: section == 6}"
                  matTooltip="Roles"
                  matTooltipPosition="below">
                <img src="../../../../assets/images/roles.png">
              </button>
          </li>
        </ul>
      </div>
    </div>
  </mat-nav-list>