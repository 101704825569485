import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { AlertService } from 'src/app/services/alert/alert.service';
import { FunctionsService } from 'src/app/services/base/functions.service';
import { ModuloService } from 'src/app/services/modulo.service';

@Component({
  selector: 'app-edit-modulo',
  templateUrl: './edit-modulo.component.html',
  styleUrls: ['./edit-modulo.component.css']
})
export class EditModuloComponent implements OnInit {

  public Editor = ClassicEditor;

  form = this.formBuilder.group({
    nombre: ['', [Validators.required]],
    orden: [''],
    descripcion: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<EditModuloComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder:FormBuilder, 
    private moduloService: ModuloService,
    private alert: AlertService,
    public fxc: FunctionsService
  ) { }

  ngOnInit(): void {
    if (this.data.name) {
      this.cargarDatos();
    }
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    this.alert.showLoading();

    const formData = new FormData();
    formData.append('name', this.form.value.nombre);
    formData.append('description', this.form.value.descripcion);

    this.moduloService.postSubModulo(this.data.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.alert.closeLoading();
          this.dialogRef.close(true);
        }
      },
      (err: any) => {
        this.alert.closeLoading();
        this.alert.showSave('error');
      });
  }

  cargarDatos() {
    this.form.reset({
      nombre: this.data.name,
      descripcion: this.data.description,
      orden: this.data.sort
    });
  }

  actualizar() {
    let datos = {
      name: this.form.value.nombre,
      sort: this.form.value.orden,
      description: this.form.value.descripcion
    };
    if (this.form.get('orden') && this.data.level >=3) {
      let ctrl: any = this.form.get('orden');
      if (ctrl.value==null || ctrl.value=='') {
        this.alert.showInfoCustom('Debe ingresar el orden');
        return;
      }
    }
    this.moduloService.putModulo(this.data.id, datos)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.showUpdate("error");
      });
  }

}
