<h5 md-dialog-title id="modal-title" *ngIf="data.name">Editar Sub-Módulo</h5>
<h5 md-dialog-title id="modal-title" *ngIf="!data.name">Nuevo Sub-Módulo</h5>
<mat-dialog-content>
    <form [formGroup]="form" class="formulario" autocomplete="off">
        <mat-form-field>
            <input matInput placeholder="Nombre"
                formControlName="nombre"
                required>
        </mat-form-field>
        <mat-form-field *ngIf="data.name && data.level >= 3">
            <input matInput placeholder="Orden"
                formControlName="orden"
                (keypress)="fxc.numberOnly($event)"
                maxlength='2'
                required>
        </mat-form-field>
        <ckeditor [editor]="Editor" [config]="{toolbar: ['bold', 'italic', '|', 'BulletedList']}" formControlName="descripcion" style="height: 400px;"></ckeditor>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="data.name">Actualizar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="!data.name">Guardar</button>
</mat-dialog-actions>