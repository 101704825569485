<h5 md-dialog-title id="modal-title" *ngIf="!data.info">Nuevo: Banner {{data.name_banner}}</h5>
<h5 md-dialog-title id="modal-title" *ngIf="data.info">Editar: Banner {{data.name_banner}}</h5>
<mat-dialog-content>
    <form [formGroup]="form1" class="formulario" autocomplete="off" *ngIf="data.type_id == 1">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
        </div>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" style="height: 60px;"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Mostrar dialogo</mat-label>
            <mat-select formControlName="tag" >
              <mat-option value="SI" >Mostrar dialogo</mat-option>
              <mat-option value="NO" >Ocultar dialogo</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="row" style="height: 75px;">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Fecha visualizacion</mat-label>
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <input matInput [matDatepicker]="date_"
                        formControlName="date_"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="date_"></mat-datepicker-toggle>
                    <mat-datepicker #date_></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Fecha expiración</mat-label>
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <input matInput [matDatepicker]="expire"
                        formControlName="expire"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="expire"></mat-datepicker-toggle>
                    <mat-datepicker #expire></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
    <form [formGroup]="form2" class="formulario" autocomplete="off" *ngIf="data.type_id == 2">
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput
                        formControlName="tag"
                        placeholder="Tag">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="url"
                        placeholder="Url">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="picker1"
                        formControlName="fecha"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 75px;">
            <div class="col-md-8">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
            <div class="col-md-4">
                <mat-form-field style="width: 35px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
    </form>
    <form [formGroup]="form3" class="formulario" autocomplete="off" *ngIf="data.type_id == 3">
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field style="width: 35px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
        <ckeditor [editor]="Editor"
            [config]="{toolbar: ['bold', 'italic', '|', 'BulletedList']}"
            formControlName="descripcion">
        </ckeditor>
        <div class="row" style="height: 75px;">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="form4" class="formulario" autocomplete="off" *ngIf="data.type_id == 4">
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput
                        formControlName="tag"
                        placeholder="Tag">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="url"
                        placeholder="Url">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field style="width: 35px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" style="height: 60px;"></textarea>
        </mat-form-field>
        <div class="row" style="height: 75px;">
            <div class="col-md-8">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
        </div>
    </form>
    <!-- INFO ARIS -->
    <form [formGroup]="form5" class="formulario" autocomplete="off" *ngIf="data.type_id == 5">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
                <mat-form-field>
                    <input matInput
                        formControlName="url"
                        placeholder="Url">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field style="width: 40px;">
                    <input matInput
                        formControlName="color"
                        placeholder="Color"
                        type="color">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Fecha visualizacion</mat-label>
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <input matInput [matDatepicker]="date_"
                        formControlName="date_"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="date_"></mat-datepicker-toggle>
                    <mat-datepicker #date_></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Fecha expiración</mat-label>
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <input matInput [matDatepicker]="expire"
                        formControlName="expire"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="expire"></mat-datepicker-toggle>
                    <mat-datepicker #expire></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 75px;">
            <div class="col-md-8">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
        </div>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" style="height: 60px;"></textarea>
        </mat-form-field>
    </form>
    <form [formGroup]="form6" class="formulario" autocomplete="off" *ngIf="data.type_id == 6">
        <div class="row">
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="picker1"
                        formControlName="fecha"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="url"
                        placeholder="Url">
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 75px;">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png"
                    #fileUpload>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver Imagen</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png<br>Tamaño max. 1Mb</mat-hint>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="form7" class="formulario" autocomplete="off" *ngIf="data.type_id == 7">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                        formControlName="titulo"
                        placeholder="Titulo">
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="height: 75px;">
            <div class="col-md-12">
                <input type="file" class="file-input"
                    (change)="onFileSelected($event)"
                    accept="jpg, jpeg, png, mp4"
                    #fileUpload>
                
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Fecha visualizacion</mat-label>
                            <mat-hint>DD/MM/AAAA</mat-hint>
                            <input matInput [matDatepicker]="date_"
                                formControlName="date_"
                                readonly>
                            <mat-datepicker-toggle matSuffix [for]="date_"></mat-datepicker-toggle>
                            <mat-datepicker #date_></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Fecha expiración</mat-label>
                            <mat-hint>DD/MM/AAAA</mat-hint>
                            <input matInput [matDatepicker]="expire"
                                formControlName="expire"
                                readonly>
                            <mat-datepicker-toggle matSuffix [for]="expire"></mat-datepicker-toggle>
                            <mat-datepicker #expire></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="file-upload">
                    {{fileName || "Seleccione imagen o video"}}
                    <button mat-mini-fab color="primary" class="upload-btn"
                            (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <button mat-button *ngIf="urlImage!=''" (click)="visualizarImagen()">Ver imagen</button>
                    <button mat-button *ngIf="urlVideo!=''" (click)="visualizarVideo()">Ver video</button>
                    <br><mat-hint>Formato de archivo: jpg, jpeg, png, mp4<br>Tamaño máximo: imagen 4Mb y video 100Mb</mat-hint>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="secondary" mat-dialog-close>Cancelar</button>
    <button mat-raised-button class="primary" (click)="guardar()" *ngIf="!data.info">Guardar</button>
    <button mat-raised-button class="primary" (click)="actualizar()" *ngIf="data.info">Actualizar</button>
</mat-dialog-actions>