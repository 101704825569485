import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

    private base_url = environment.base_url;
    private _refresh = new Subject<void>();

    constructor(private http: HttpClient) { }

    getBanners(type_id: number) {
        return this.http.get(`${ this.base_url }/banner?type=${ type_id }`);
    }
    
    getBannerById(id:number) {
        return this.http.get(`${ this.base_url }/banner/${ id }?expand=file`);
    }

    postBanner(formData: FormData ) {
        return this.http.post(`${ this.base_url }/banner`, formData);
    }

    postUpdateBanner(id: number, formData: FormData ) {
        return this.http.post(`${ this.base_url }/banner/${ id }`, formData);
    }

    deleteBanner(id:number) {
        return this.http.delete(`${ this.base_url }/banner/${ id }`)
        .pipe(
            tap(() => {
            this._refresh.next();
            })
        );
    }
}