import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/services/alert.service';
import { OrganigramaService } from 'src/app/services/organigrama.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { EditPerOrganigramaComponent } from './edit-per-organigrama/edit-per-organigrama.component';
import { NewPerOrganigramaComponent } from './new-per-organigrama/new-per-organigrama.component';

@Component({
  selector: 'app-organigrama',
  templateUrl: './organigrama.component.html',
  styleUrls: ['./organigrama.component.css']
})
export class OrganigramaComponent implements OnInit {

  displayedColumns: string[] = ['columna', 'nivel', 'nombre', 'cargo', 'activo', 'opciones'];
  dataSource = new MatTableDataSource();
  public url = environment.url;

  constructor(
    private organigramaService: OrganigramaService,
    private alert: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.listar();
  }

  listar() {
    this.organigramaService.getOrganigrama()
      .subscribe((data:any) => {
        this.dataSource = new MatTableDataSource(data.data.data);
      });
  }

  doFilter(ev: any) {
    let strBuscar = ev.target.value;
    this.dataSource.filter = strBuscar.trim().toLocaleLowerCase();
  }

  agregar() {
    const dialogRef = this.dialog.open(NewPerOrganigramaComponent, {
      disableClose: true,
      width: '520px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.listar();
        }
      });
  }

  editar(id: number) {
    this.organigramaService.getOrganigramaById(id)
      .subscribe((data: any) => {
        if (data.status !== 200) {
          this.alert.showInfo('warning');
          return;
        }
        const dialogRef = this.dialog.open(EditPerOrganigramaComponent, {
          disableClose: true,
          width: '540px',
          data: data.data.data
        });
        dialogRef.afterClosed()
          .subscribe((result: any) => {
            if (result) {
              this.listar();
            }
          });
      },
      (err) => {
        this.alert.showInfo('error');
      });
  }

  eliminar(id: number) {
    let param: {} = this.alert.getParamConfirm();
    Swal.fire(param)
      .then((result) => {
        if (result.isConfirmed) {
          this.organigramaService.deleteItemOrganigrama(id)
            .subscribe((data: any) => {
              if (data.data.status !== 200) {
                this.alert.showWarningCustom(data.data.mensaje);
                return;
              } else {
                this.alert.showDelete('ok');
                this.listar();
              }
            });
        }
      });
  }

}
